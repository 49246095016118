import styled from "styled-components";
import QuestionHeader from "./__common__/styled-components";

export const StyledQuestionHeader = styled(QuestionHeader)`
    justify-content: center;
    & > h4{
        text-align: center;
        margin-left: 0;
    }

    @media screen and (max-width: 768px){
        & > h4{
            padding: 0 18% 0 25%;
            text-align: left;
            &:first-child::after{
                left: 21%;
                ${'' /* height: 250%; */}
            }
        }
    }
`;
