function allSlidersTouched(slideId, dontShowErrorMessage) {
    const sliders = this.state.slidersTouched;

    for (var i in sliders) {
        if (sliders[i] === false) {
            if (!dontShowErrorMessage) {
                this.setState({
                    showDefaultValuesWarning: true
                });
            }
            return false;
        }
    }
    this.setState({
        showDefaultValuesWarning: false,
        allSidersWereTouched: true
    });
    this.props.doSetAllSlidersWereTouched(slideId, {
        allSidersWereTouched: true
    });
    return true;
}

function handleSliderTouch(sliderName) {
    if (this.state.slidersTouched[sliderName] === false) {
        let _slidersState = Object.assign(this.state.slidersTouched);
        _slidersState[sliderName] = true;
        this.setState({
            slidersTouched: _slidersState
        });
    }
}

function generateSliderState(sliderName) {
    // if no function provided you need to generate state in parent
    if (this.state.slidersTouched[sliderName] === undefined) {
        let _slidersState = Object.assign(this.state.slidersTouched);
        _slidersState[sliderName] = false;
        this.setState({
            slidersTouched: _slidersState
        });
    }
}


export {
    allSlidersTouched,
    handleSliderTouch,
    generateSliderState
};