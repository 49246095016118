import { contentActionTypes } from "../actions";
const initialState = {};

export const applyContent = (state = initialState, action) => {
    switch (action.type) {
        case contentActionTypes.CONTENT_FETCHED:
            return { ...action.content };

        default:
            return state;
    }
}
