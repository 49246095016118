import React from "react";
import styled from "styled-components";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import ScrollBarArea from "../__common__/ScrollBarArea";
import "./animation.css";

const StyledScrollBarArea = styled(ScrollBarArea)`
    height: 40vh !important;
    font-size: 0.90vw;
    
    @media screen and (max-width: 1024px){
        font-size: 1.6vw;
    }
    @media screen and (max-width: 768px) and (max-aspect-ratio: 500/1024) {
        height: 50vh !important;
    }

`;

const StyledTransitionGroup = styled(TransitionGroup)`
    width: 90%;
    margin-left: 5%;
    margin-top: 1vw;
    margin-bottom: 1vw;
    flex: 1 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    
    padding-bottom: 3em;
    
    /* because children has size of 32%, cannot use space-around because it makes 2 elements per row look weird */

    & > input:nth-child(3n+1) {
        margin-right: 1.5%;
    }
    
    & > input:nth-child(3n+2) {
        margin-right: 1.5%;
    }

    @media screen and (max-width: 1024px){

    }
    @media screen and (max-width: 768px){
        height: 100%;
        width: 65%;
        margin: 0 auto;
        padding-bottom: 25vw;
        
        & > input:nth-child(3n+1) {
            margin-right: 0;
        }
        
        & > input:nth-child(3n+2) {
            margin-right: 0;
        }
    }

`;

const StyledInput = styled.input.attrs({
    type: 'text'
})`
    flex-basis: 32%;
    display: flex;
    justify-content: center;
    border: 0;
    font-weight: 200;
    font-size: 0.9vw;
    letter-spacing: 0.3pt;
    height: 2.5em;
    text-align: center;
    color: white;
    background-color: transparent;
    outline: none;
    @media screen and (max-width: 1024px){
        font-weight: 200;
        font-size: 1.5vw;
        
    }
    @media screen and (max-width: 768px){
        font-weight: 200;
        font-size: 5vw;        
        flex-basis: 100%;
        
    }
`;

const TeamManager = ({ teamMembers, onMemberChange }) => {
    return (
        <StyledScrollBarArea>
            <div>
                <StyledTransitionGroup>
                    {teamMembers.map(x =>
                        <CSSTransition key={x.id} timeout={300} classNames="fade">
                            <StyledInput className={!x.name ? "validation-error" : null}
                                maxLength="50"
                                value={x.untrimmed}
                                placeholder={x.placeholder}
                                onChange={event => onMemberChange(event, x.id)}
                            />
                        </CSSTransition>
                    )}
                </StyledTransitionGroup>
            </div>
        </StyledScrollBarArea>

    )
}
export default TeamManager;
