import styled from 'styled-components';


export const Wrapper = styled.div`
    flex-basis: 25%;
    height: 100%;

    @media screen and (max-width: 1024px){

    }

    @media screen and (max-width: 768px){
        display: none;
    }
`;

export const StyledNav = styled.nav`
    flex-basis: 25%;
    height: 65%;
    display: flex;
    position: relative;
    padding-top: 60%;

    @media screen and (max-width: 1024px){
        margin-left: 15%;
    }
    @media screen and (max-width: 768px){

    }
`;

export const List = styled.ul`
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    transition: 0.1s all ease-in-out;
    bottom: ${props => props.bottom + 'px'};

    position: relative;
    transform: translateY(-4px);
    
    @media screen and (max-width: 1024px){

    }
    @media screen and (max-width: 768px){

    }

`;

export const BoundaryLine = styled.div`
    height: 85%;
    width: 1px;
    z-index: -1;
    position: absolute;
    top: 0;
    left: 4px;
    background-color: rgba(146,202,177, 0.4);
    box-shadow: 0 0 3px rgba(146,202,177,0.6);
    &::before{
        content: '';
        display: block;
        width: 1px;
        height: 5%;
        transform: translateY(-100%);
        background-color: rgba(146,202,177, 0.4);
        box-shadow: 0 0 3px rgba(146,202,177,0.6);
    }
    &::after{
        content: '';
        display: block;
        width: 1px;
        height: 5%;
        transform: translateY(100%);
        position: absolute;
        bottom: 0;
        background-color: rgba(146,202,177, 0.4);
        box-shadow: 0 0 3px rgba(146,202,177,0.6);
    }

    @media screen and (max-width: 1024px){
        height: 70%;       
        left: 3px;

    }
    @media screen and (max-width: 768px){

    }
`;

export const SlideNumber = styled.div`
    width: 100%;
    height: 0%;
    display: flex;
    align-items: center;
    font-size: 1.65vw;
    font-weight: 600;
    ${'' /* padding-top: 60%; */}
    &::before{
        content: '';
        display: block;
        height: 2px;
        width: 65%;
        background-color: rgba(146,202,177, 0.2);
        margin-right: 0.3em;
    }
    &::after{
        content: '';
        display: flex;
        height: 3em;
        width: 1px;
        background-color: rgba(146,202,177,0.8);
        margin-left: 1.2em;
        position: absolute;
        right: 0.5em;
        
        top: 0%;
        margin-top: 60%;
        transform: translateY(-50%);
    }

    @media screen and (max-width: 1024px){
        font-size: 2.35vw;

        &::after{
            margin-top: 70%;
        }
    }
    @media screen and (max-width: 768px){

    }    
`;
