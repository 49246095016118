import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Wrapper } from "./styles";
import RadioWithText from "../__common__/RadioWithText";

const ExRadioWithText = styled(RadioWithText)`
    & div{
        margin-bottom: 1vw;
        padding-bottom: 7px;
    }
`;


class RadioContainer extends React.Component {

    static propTypes = {
        selectedRadioId: PropTypes.any,
        onSelect: PropTypes.func.isRequired,
        questionId: PropTypes.any.isRequired
    }

    handleSelect = (selectedRadioId) => {
        this.props.onSelect(selectedRadioId, this.props.questionId);
    }
    render() {
        const { selectedRadioId, className } = this.props;
        const { somewhat_agree, strongly_disagree, agree, disagree, somewhat_disagree, dont_know, strongly_agree } = this.props.content;
        let classes = [];

        if (selectedRadioId == null) {
            classes.push('validation-error');
        }

        if (className) {
            classes.push(className)
        }

        return (
            <Wrapper className={classes.join(' ')}>
                <ExRadioWithText id={1} selectedRadioId={selectedRadioId} onSelect={this.handleSelect} text={strongly_disagree} />
                <ExRadioWithText id={2} selectedRadioId={selectedRadioId} onSelect={this.handleSelect} text={disagree} />
                <ExRadioWithText id={3} selectedRadioId={selectedRadioId} onSelect={this.handleSelect} text={somewhat_disagree} />
                <ExRadioWithText id={4} selectedRadioId={selectedRadioId} onSelect={this.handleSelect} text={dont_know} />
                <ExRadioWithText id={5} selectedRadioId={selectedRadioId} onSelect={this.handleSelect} text={somewhat_agree} />
                <ExRadioWithText id={6} selectedRadioId={selectedRadioId} onSelect={this.handleSelect} text={agree} />
                <ExRadioWithText id={7} selectedRadioId={selectedRadioId} onSelect={this.handleSelect} text={strongly_agree} />
            </Wrapper>
        )
    }
}
const mapStateToProps = (state) => ({
    content: state.applyContent.umb_dictionary
})
export default connect(mapStateToProps, {})(RadioContainer)