export const selectMenuItem = (menuItemId) => ({
    type: 'SELECT_MENU_ITEM',
    selectedMenuItemId: menuItemId
})

export const doGoForward = () => ({
    type: 'GO_FORWARD'
})

export const doGoBackward = () => ({
    type: 'GO_BACKWARD'
})

export const doSetVisited = (visitedMenuItemId) => ({
    type: 'SET_VISITED',
    visitedMenuItemId
})

export const slideActionTypes = {
    SEND_SLIDE_1_DATA: 'SEND_SLIDE_1_DATA',
    SEND_SLIDE_2_DATA: 'SEND_SLIDE_2_DATA',
    SEND_SLIDE_3_DATA: 'SEND_SLIDE_3_DATA',
    SEND_SLIDE_4_DATA: 'SEND_SLIDE_4_DATA',
    SEND_SLIDE_5_DATA: 'SEND_SLIDE_5_DATA',
    SEND_SLIDE_6_DATA: 'SEND_SLIDE_6_DATA',
    SEND_SLIDE_7_DATA: 'SEND_SLIDE_7_DATA',
    SEND_SLIDE_8_DATA: 'SEND_SLIDE_8_DATA',
    SEND_SLIDE_9_DATA: 'SEND_SLIDE_9_DATA',
    SEND_SLIDE_10_DATA: 'SEND_SLIDE_10_DATA',
    SEND_SLIDE_11_DATA: 'SEND_SLIDE_11_DATA',
    CONTENT_FETCHED: 'CONTENT_FETCHED'
}
export const contentActionTypes = {
    CONTENT_FETCHED: 'CONTENT_FETCHED',
}

export const doSendSlide1Data = (teamMembers) => ({
    type: slideActionTypes.SEND_SLIDE_1_DATA,
    teamMembers
})

export const doSendSlide2Data = (teamMembers) => ({
    type: slideActionTypes.SEND_SLIDE_2_DATA,
    teamMembers
})

export const doSendSlide3Data = (proximity) => ({
    type: slideActionTypes.SEND_SLIDE_3_DATA,
    proximity
})

export const doSendSlide4Data = (bureaucracy) => ({
    type: slideActionTypes.SEND_SLIDE_4_DATA,
    bureaucracy
})

export const doSendSlide5Data = (questions) => ({
    type: slideActionTypes.SEND_SLIDE_5_DATA,
    questions
})

export const doSendSlide6Data = (method_statements) => ({
    type: slideActionTypes.SEND_SLIDE_6_DATA,
    method_statements
})

export const doSendSlide7Data = (teamMembers) => ({
    type: slideActionTypes.SEND_SLIDE_7_DATA,
    teamMembers
})

export const doSendSlide8Data = (legacy) => ({
    type: slideActionTypes.SEND_SLIDE_8_DATA,
    legacy
})

export const doSendSlide9Data = (apiPercent) => ({
    type: slideActionTypes.SEND_SLIDE_9_DATA,
    apiPercent
})

export const doSendSlide10Data = (reusability) => ({
    type: slideActionTypes.SEND_SLIDE_10_DATA,
    reusability
})

export const doSendSlide11Data = (futureProofing) => ({
    type: slideActionTypes.SEND_SLIDE_11_DATA,
    futureProofing
})

export const doContentFetched = (content) => ({
    type: contentActionTypes.CONTENT_FETCHED,
    content
})