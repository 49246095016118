import styled from "styled-components";
export const Wrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const Value = styled.span`
    font-size: ${props => props.smaller ? '0.9vw' : '1.3vw'};
    min-width: ${props => props.smaller ? '2.5vw' : '2.4vw'};
    min-height: 17px;
    font-weight: 800;
    text-align: center;
    color: ${props => props.hasChanged ? 'white' : 'rgba(255,255,255,0.6)'};
    position: relative;
    @media screen and (max-width: 1024px){
        font-size: ${props => props.smaller ? '1.4vw' : '2vw'};
        min-width: ${props => props.smaller ? '3.5vw' : '2.4vw'};
        
    }
    @media screen and (max-width: 768px){
        padding: 0 0.65em;        
        font-size: ${props => props.smaller ? '4.5vw' : '8vw'};
    }
`;

export const Arrow = styled.div`
    width: 0; 
    height: 0; 

    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent; 
    border-right: ${props => props.right && '9px solid rgba(146,202,177,1)'};
    border-left: ${props => props.left && '9px solid rgba(146,202,177,1)'};

    position: relative;
    cursor: pointer;
    transition: 0.15s transform ease-in-out;

    &:hover{
        transform: scale(1.2);
    }

    &::before{
        content: '';
        display: block;
        position: absolute;
        width: 30px;
        height: 25px;
        transform: translate(-50%,-50%);
    }
    
    @media screen and (max-width: 1024px){}
    @media screen and (max-width: 768px){
        border-top: 11px solid transparent;
        border-bottom: 11px solid transparent; 
        border-right: ${props => props.right && '20px solid rgba(146,202,177,1)'};
        border-left: ${props => props.left && '20px solid rgba(146,202,177,1)'};
    }
`; 
