//TODO: Add prefix to reducer and change name
import Slide1 from "../components/Slides/Slide1";
import Slide2 from "../components/Slides/Slide2";
import Slide3 from "../components/Slides/Slide3";
import Slide4 from "../components/Slides/Slide4";
import Slide5 from "../components/Slides/Slide5";
import Slide6 from "../components/Slides/Slide6";
import Slide7 from "../components/Slides/Slide7";
import Slide8 from "../components/Slides/Slide8";
import Slide9 from "../components/Slides/Slide9";
import Slide10 from "../components/Slides/Slide10";
import Slide11 from "../components/Slides/Slide11";

const menuItems = [
    { id: 1, isVisited: true, exact: true, path: '/assessment/1', component: Slide1, tooltip: 'Team' },
    { id: 2, isVisited: false, exact: false, path: '/assessment/2', component: Slide2, tooltip: 'Current competence level' },
    { id: 3, isVisited: false, exact: false, path: '/assessment/3', component: Slide3, tooltip: 'Proximity to decision maker' },
    { id: 4, isVisited: false, exact: false, path: '/assessment/4', component: Slide4, tooltip: 'Bureaucracy' },
    { id: 5, isVisited: false, exact: false, path: '/assessment/5', component: Slide5, tooltip: 'Culture' },
    { id: 6, isVisited: false, exact: false, path: '/assessment/6/1', component: Slide6, tooltip: 'Method' },
    { id: 7, isVisited: false, exact: false, path: '/assessment/7', component: Slide7, tooltip: 'Method' },
    { id: 8, isVisited: false, exact: false, path: '/assessment/8', component: Slide8, tooltip: 'Legacy' },
    { id: 9, isVisited: false, exact: false, path: '/assessment/9', component: Slide9, tooltip: "Architecture" },
    { id: 10, isVisited: false, exact: false, path: '/assessment/10', component: Slide10, tooltip: 'Architecture' },
    { id: 11, isVisited: false, exact: false, path: '/assessment/11', component: Slide11, tooltip: 'Architecture' },
]

export const navmenu = (state = {
    selectedMenuItemId: 1,
    selectedSubpage: 0,
    slide5_selectedStatement: 1,
    menuItems: menuItems
}, action) => {
    switch (action.type) {
        case 'SELECT_MENU_ITEM':
            return { ...state, selectedMenuItemId: action.selectedMenuItemId, selectedSubpage: action.selectedMenuItemId ? 1 : 0 };
        case 'GO_FORWARD':
            return { ...state, selectedMenuItemId: state.selectedMenuItemId + 1 };
        case 'GO_BACKWARD':
            return { ...state, selectedMenuItemId: state.selectedMenuItemId - 1 };
        case 'SET_VISITED':
            return {
                ...state,
                menuItems: state.menuItems.map(item => {
                    if (item.id === action.visitedMenuItemId) {
                        item.isVisited = true;
                    }
                    return item;
                })
            }

        default:
            return state;
    }
}

