import * as React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import {Row, Cell, MobileCellText} from "./styles";
import { Img } from "./index";
import SpeechBubble from "../../resources/speech-bubble.svg";
import NativeNumberInput from "../__common__/NativeNumberInput/NativeNumberInput";

// const StyledNativeNumberInput = styled(NativeNumberInput)`
//     @media screen and (max-width: 768px){
//         display: none;
//     }
// `;

// const ExRangeInput = styled(RangeInput)`
//     display: none;
//
//     @media screen and (max-width: 768px){
//         width: 100%;
//         display: flex;
//         margin: 4vw 0 15vw 0;
//         & .degrees-container{
//             & div{
//                 width: 100%;
//             }
//         }
//     }
// `;

const StyledInput = styled.input.attrs({
    type: 'text',
})`
    font-weight: 200;
    font-size: 0.9vw;
    letter-spacing: 0.3pt;
    height: 2.5em;
    text-align: center;
    color: white;
    background-color: transparent;
    outline: none;  
    border: 0;
    width: 6vw;
    
    @media screen and (max-width: 1024px){
        font-weight: 200;
        font-size: 1.5vw;
    }
    
    @media screen and (max-width: 768px){
        font-weight: 200;        
        font-size: 5vw;                
        // flex-basis: 100%;
        width: 50%;
    }
`;

const MIN_COMPETENCE_LEVEL = 1,
    MAX_COMPETENCE_LEVEL = 10;

class TableRow extends React.Component {

    static propTypes = {
        onMemberChange: PropTypes.func.isRequired,
        member: PropTypes.any.isRequired
    };

    state = {
        id: null,
        name: '',
        competence: '',
        yearlySalary: null,
        hasChanged: 0
    };

    constructor(props) {
        super(props);

        const { id, name, competence, yearlySalary, hasChangedManualInput } = this.props.member;

        this.state = {
            id: id,
            name: name,
            competence: competence,
            yearlySalary: yearlySalary,
            hasChangedManualInput: hasChangedManualInput,
            hasChanged: false
        };
    }

    handleCompetenceIncrement = () => {
        const competence = Number(this.state.competence);
        if (competence === MAX_COMPETENCE_LEVEL) return;

        this.setState((prevState) => {
            let prevCompetence = !prevState.competence ? (MIN_COMPETENCE_LEVEL - 1) : +prevState.competence;

            return {
                competence: prevCompetence + 1,
                hasChanged: true
            }
        }, this.handleMemberChange);
    };

    handleCompetenceDecrement = () => {
        const competence = Number(this.state.competence);
        if (competence === '' || competence === MIN_COMPETENCE_LEVEL) return; // cannot decrement from start value ('')

        this.setState(prevState => {
            let prevCompetence = +prevState.competence;
            return {
                competence: prevCompetence - 1,
                hasChanged: true
            }
        }, this.handleMemberChange);
    };

    handleManualChange = (event) => {
        let value = Number(event.target.value);

        if (value !== '' && ((+value > MAX_COMPETENCE_LEVEL)
            || (+value === 0 && value.toString().length > 1)
            || (+value < MIN_COMPETENCE_LEVEL))) return;

        this.setState({
            competence: value,
            hasChanged: true
        }, this.handleMemberChange);
    };

    handleSalaryChange = (event) => {
        let value = event.target.value;
        value = value.replace(/([^0-9.]+)/g, ''); // remove non-numbers and non-dots

        // Math.trunc here is used to keep only integer part of the value that (may) be copied or entered
        // Without it a much higher value could be interpreted (20.5 -> 205)
        this.setState({ yearlySalary: value === '' ? '' : Math.trunc(+value) }, this.handleMemberChange);
    };

    handleMemberChange = () => this.props.onMemberChange(this.state);

    render() {
        const { name, competence, yearlySalary, hasChanged } = this.state;
        return (
            <Row>
                <Cell title={name}> <div className="ellipsis">{name}</div></Cell>
                <Cell>
                    <MobileCellText>{this.props.tableContent.yearlySalary}</MobileCellText>
                    <StyledInput formnovalidate
                                 value={yearlySalary || ''}
                                 placeholder="Enter salary"
                                 className={yearlySalary === '' ? "validation-error" : null}
                                 onChange={this.handleSalaryChange}
                                 maxLength="7" />
                </Cell>
                <Cell>
                    <MobileCellText>
                        {this.props.tableContent.currentCompetence}
                        <Img src={SpeechBubble} alt="speech bubble" onClick={() => this.props.showModal()} />
                    </MobileCellText>

                    <NativeNumberInput hasChanged={hasChanged}
                                       value={competence || ''}
                                       placeholder="Level"
                                       min={MIN_COMPETENCE_LEVEL}
                                       max={MAX_COMPETENCE_LEVEL}
                                       isValidated={+competence >= MIN_COMPETENCE_LEVEL && +competence <= MAX_COMPETENCE_LEVEL}
                                       onDecrement={this.handleCompetenceDecrement}
                                       onIncrement={this.handleCompetenceIncrement}
                                       onChange={this.handleManualChange}/>
                </Cell>
            </Row>
        )
    }
}


export default TableRow;