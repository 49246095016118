import React from "react";
import styled from "styled-components";

export const SlideHeader = ({ title, text }) => {
    return (
        <StyledHeader>
            <H3>{title}</H3>
            <P>{text}</P>
        </StyledHeader>
    )
}
export const StyledHeader = styled.header`
    height: 20%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    &::after {
        content: '';
        display: block;
        height: 1px;
        width: 100%;
        background-color: #92CAB1;
    }

    // @media screen and (max-width: 1024px){
    //     height: 20%;
    // }

    @media screen and (max-width: 768px){
        // height: auto;
        // min-height: 12.5%;
        height: 15%;

        width: 100%;
        padding: 0 35%;
        margin: 0 auto;

        display: flex;
        justify-content: center;
        align-items: center;
        
        &::after{
            display: none;
        }
    }
`;

export const H3 = styled.h3`
    text-transform: uppercase;
    margin: 0 0 0.4em 0; 
    font-weight: 500;
    letter-spacing: 0.3pt;
    font-size: 1.15vw;
    
    @media screen and (max-width: 1024px){
        font-size: 1.95vw;
        
    }
    @media screen and (max-width: 768px){
        font-size: 4.5vw;
        font-weight: 300;
        text-align: center;
    }

`;

export const P = styled.p`
    text-align: justify;
    font-size: 0.95vw;
    margin: 0.6em 0 1em 0;
    line-height: 1.2em;
    letter-spacing: 0.2pt;

    @media screen and (max-width: 1024px){
        font-size: 1.5vw;
        letter-spacing: 0.1pt;
        

    }
    @media screen and (max-width: 768px){
        display: none;
    }
`;
