import * as React from "react";
import BaseSlide from "../BaseSlide";
import styled from "styled-components";
import QuestionWithInput from "./QuestionWithInput";
import QuestionWithRadio from "./QuestionWithRadio";

import { connect } from "react-redux";
import { doSendSlide8Data } from "../../../actions";
import { doSetAllSlidersWereTouched } from '../../../actions/allSlidersWereTouched';
import { doSetSlideWarningShown } from '../../../actions/slideWarningShown';
import { allSlidersTouched, handleSliderTouch, generateSliderState } from "../__common__/RangeInput/sliderParentCallbacks";
import { findFirstFocusableElement } from '../__common__/firstFocusableHelper';
import Dropdown from "../__common__/Dropdown";
import QuestionHeader, { H4 } from "../__common__/styled-components";
import ScrollBarArea from "../__common__/ScrollBarArea";
import {Config} from "../../../Config";

const MobileContainer = styled.div`
    display: none;
    @media screen and (max-width: 768px){
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        margin-bottom: 5vw;
    }
`;

const DesktopContainer = styled.div`
    height: 48vh;
    
    @media screen and (max-width: 768px){
        display: none;
    }
`;

const QuestionWrapper = styled.div`
    padding-bottom: 1em;
`;

class Slide8 extends React.Component {
    state = {
        legacy: {},
        isValidated: true,
        input: true,
        selectedId: 0,
        showDefaultValuesWarning: false,
        slidersTouched: {},
        allSidersWereTouched: false,
        warningMessageShown: false
    }

    static getDerivedStateFromProps(nextProps) {
        return {
            legacy: nextProps.legacy,
            allSidersWereTouched: nextProps.allSidersWereTouched.allSidersWereTouched,
            warningMessageShown: nextProps.warningMessageShown.warningMessageShown
        }
    }

    handleSliderTouch = handleSliderTouch.bind(this);
    allSlidersTouched = allSlidersTouched.bind(this, 8);
    allSlidersTouchedNoWarningTrigger = allSlidersTouched.bind(this, 8, true);
    generateSliderState = generateSliderState.bind(this);
    findFirstFocusableElement = findFirstFocusableElement;

    handleGoForward = () => {
        this.setState({
            nextPressed: true
        });

        // If radio was selected and scrollbars were touched
        if (this.state.legacy.third === 1 || this.state.legacy.third === 2) {
            if (this.allSlidersTouched() || this.state.allSidersWereTouched || this.state.warningMessageShown) {
                return true;
            } else {
                this.setState({
                    showDefaultValuesWarning: true,
                    warningMessageShown: true
                });
                this.props.doSetSlideWarningShown(8, {warningMessageShown: true});
                return false;
            }
        } else {
            this.setState({
                isValidated: false,
                input: false
            });
            return false;
        }
    };

    handleHistoricReasonsChange = (value) => {
        const { legacy } = this.state;
        this.allSlidersTouchedNoWarningTrigger();
        this.props.doSendSlide8Data({ ...legacy, historicReasons: value });
    };

    handleEffortChange = (value) => {
        const { legacy } = this.state;
        this.allSlidersTouchedNoWarningTrigger();
        this.props.doSendSlide8Data({ ...legacy, effort: value });
    }

    handleRadioChange = (id) => {
        const { legacy } = this.state;
        this.setState({ input: true, isValidated: true });
        this.props.doSendSlide8Data({ ...legacy, third: id });
    };

    handleSelectChange = (e) => {
        const selectedId = e.target.value;
        this.setState({ selectedId: +selectedId });
    };

    componentDidMount() {
        this.firstFocusableElement = this.findFirstFocusableElement();

        if (window.gtag) {
            window.gtag('config', Config.GOOGLE_ANALYTICS, {
                'page_title' : 'Slide 8',
                'page_path': `/assessment/8`
            });
        }
    }

    render() {
        const { legacy, selectedId, nextPressed, isValidated } = this.state;
        const { header, description, question1, question2, question3 } = this.props.content;
        const slideData = {
            id: 8,
            title: header,
            text: description
        }
        const translations = [
            question1, question2, question3
        ];

        return (
            <BaseSlide {...slideData} nextPressed={nextPressed} isValidated={!nextPressed || isValidated} validationErrorMessage="Please answer yes or no to the third statement." onGoForward={this.handleGoForward} showDefaultValuesWarning={this.state.showDefaultValuesWarning} defaultValuesWarningMessage="One of the sliders is set to 0. If the value is correct click NEXT to continue the assessment." firstFocusableElement={this.firstFocusableElement}>
                <DesktopContainer>
                    <ScrollBarArea>
                        <QuestionWrapper>
                            <QuestionWithInput percent={legacy.historicReasons} text={translations[0]} onValueChange={this.handleHistoricReasonsChange} handleSliderTouch={this.handleSliderTouch} name="historicReasonSlider" allSlidersWereTouched={this.state.allSidersWereTouched} slideWarningMessageShown={this.state.warningMessageShown} generateSliderState={this.generateSliderState}/>
                            <QuestionWithInput percent={legacy.effort} text={translations[1]} onValueChange={this.handleEffortChange} handleSliderTouch={this.handleSliderTouch} name="effortSlider" allSlidersWereTouched={this.state.allSidersWereTouched} slideWarningMessageShown={this.state.warningMessageShown} generateSliderState={this.generateSliderState}/>
                            <QuestionWithRadio isValidated={!nextPressed || isValidated} selectedRadioId={legacy.third} text={translations[2]} onSelect={this.handleRadioChange}/>
                        </QuestionWrapper>
                    </ScrollBarArea>
                </DesktopContainer>
                <MobileContainer>
                    <QuestionHeader>
                        <H4 flexBasis="45%">{translations[selectedId]}</H4>
                    </QuestionHeader>
                    <Dropdown value={selectedId} items={[0, 1, 2]} onChange={this.handleSelectChange} />
                    {selectedId === 0 && <QuestionWithInput percent={legacy.historicReasons} onValueChange={this.handleHistoricReasonsChange} handleSliderTouch={this.handleSliderTouch} name="historicReasonSlider" allSlidersWereTouched={this.state.allSidersWereTouched} slideWarningMessageShown={this.state.warningMessageShown}/>}
                    {selectedId === 1 && <QuestionWithInput percent={legacy.effort} onValueChange={this.handleEffortChange} handleSliderTouch={this.handleSliderTouch} name="effortSlider" allSlidersWereTouched={this.state.allSidersWereTouched} slideWarningMessageShown={this.state.warningMessageShown} />}
                    {selectedId === 2 && <QuestionWithRadio selectedRadioId={legacy.third} onSelect={this.handleRadioChange} />}
                </MobileContainer>
            </BaseSlide>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        legacy: state.applySlidesData.legacy,
        allSidersWereTouched: state.applyAllSlidersWereTouched[7],
        warningMessageShown: state.applySlideWarningShown[7],
        content: state.applyContent.steps[7]
    }
}

const mapDispatchToProps = { doSendSlide8Data, doSetAllSlidersWereTouched, doSetSlideWarningShown };

export default connect(mapStateToProps, mapDispatchToProps)(Slide8);