import React from "react";
import styled from "styled-components";
import LogoHeader from "../../Slides/Hello/LogoHeader";
import connect from "react-redux/es/connect/connect";
import InlineSpinner from "../../InlineSpinner/index";
import { Config } from "../../../Config";

const ExLogoHeader = styled(LogoHeader)`
    margin-top: 10vw;
    @media screen and (max-width: 1024px){
       }
    @media screen and (max-width: 768px){
        margin-top: 40vw;
    }

`;

const Wrapper = styled.div`
    height: 100%;
    margin: 0 auto;
`;
// const ExProgressBar = styled(ProgressBar2)`
//     bottom: 2vw;
//     width: 70%;
//     position: absolute;
//     left: 50%;
//     transform: translate(-42%,0px);
// `;

const StyledP = styled.p`
    margin: 2vw auto 0;
    font-size: 1.3vw;
    text-align: center;
    padding: 0 14vw;

    &:first-of-type{
        margin-top: 7.5vw;
    }
    &:last-of-type{
        margin-bottom: 4vw;
    }
    @media screen and (max-width: 1024px){
        font-size: 1.8vw;
        padding: 0 18vw;
        &:last-of-type{
            margin-bottom: 8vw;
        }
    }
    @media screen and (max-width: 768px){
        font-size: 4vw;
        padding: 0 20px;
        &:first-of-type{
            margin-top: 27.5vw;
        }

        &:last-of-type{
            margin-bottom: 16vw;
        }
    }

`;

const TIME_TO_WAIT = 15; // seconds

class Finish extends React.Component {

    state = {
        reportReady: false,
        secondsLeft: TIME_TO_WAIT
    };

    componentDidMount() {
        if (window.gtag) {
            window.gtag('config', Config.GOOGLE_ANALYTICS, {
                'page_title': 'Finish',
                'page_path': `/finish`
            });
        }

        this.intervalHandler = setInterval(() => {
            const secondsLeft = this.state.secondsLeft - 1;
            this.setState({
                secondsLeft: secondsLeft,
                reportReady: secondsLeft <= 0
            });

            if (secondsLeft <= 0) {
                clearInterval(this.intervalHandler);
            }
        }, 1000)
    }

    render() {

        const reportDownloadLink = this.props.reportData.link,
            { reportReady, secondsLeft } = this.state;

        return (
            this.props.preppedSurveyInput.doNotSendEmail ?
                <Wrapper>
                    <ExLogoHeader />
                    <StyledP>Congratulations!</StyledP>
                    <StyledP>We will contact you regarding how well your team is performing and the areas you should focus on to improve.</StyledP>
                </Wrapper> :
                <Wrapper>
                    <ExLogoHeader />
                    <StyledP>Congratulations!</StyledP>
                    <StyledP>We sent you an email with your report. Now you can see how well your team is performing and the areas you should focus on to improve. If you would like your report now, please click link below.</StyledP>
                    {(reportDownloadLink && !reportReady) ? <StyledP>Download link will be ready in {secondsLeft}s... <InlineSpinner></InlineSpinner></StyledP> : ''}
                    {(reportDownloadLink && reportReady) ? <StyledP><a href={reportDownloadLink} target="_blank">Download the report</a></StyledP> : ''}
                </Wrapper>
        )
    }
}

const mapStateToProps = (state) => ({
    reportData: state.applyReportData,
    preppedSurveyInput: state.applyContent.preppedSurveyInput
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Finish);

