import React from "react";
import styled from "styled-components";
import colors from "../../Slides/__common__/colors";
const StyledLabel = styled.label`

    margin: 0.4em 0;

    position: relative;

    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 40%;
    @media screen and (max-width: 1024px){
        
    }
    @media screen and (max-width: 768px){
        width: 100%;
        ${'' /* flex-flow: column; */}
    }

`;
const StyledSpan = styled.span`
    font-size: 0.95vw;
    text-align: right;
    font-weight: 500;
    width: 150%;
    position: absolute;
    left: -150%;
    top: 50%;
    transform: translateY(-50%);
    @media screen and (max-width: 1024px){
        font-size: 1.6vw;
    }
    @media screen and (max-width: 768px){
        position: relative;
        font-size: 3.65vw;
        left: 0;
        width: auto;
        transform: translateY(0);
        top: 0;

    }

`;

const StyledInput = styled.input`
    font-size: 0.9vw;
    color: #FFFFFF;
    margin-left: 0.5em;
    padding: 0.5em 0 0.5em 0.5em;
    background-color: transparent;

    border: none;
    border-bottom: 1px solid transparent;
    outline: none;

    transition: 0.2s all ease-in-out;

    width: 100%;
    height: 2.5em;
    
    &[type="checkbox"]{
        &:focus{
            outline: auto;
        }

        @media screen and (max-width: 1024px){
            font-size: 1.5vw!important;
        }
        @media screen and (max-width: 768px){
            font-size: 1.4vw!important;
            margin-left: 0;
            margin-right: 3vw;
        }
    }
    
    @media screen and (max-width: 1024px){
        font-size: 1.5vw;
    }
    @media screen and (max-width: 768px){
        font-size: 5vw; 
        margin-left: 0;
    }

`;



const InvalidMessage = styled.span`
    color: red;
    position: absolute;
    font-size: 0.8vw;
    bottom: 50%;
    right: 0;
    transform: translate(110%, 50%);

    @media screen and (max-width: 1024px){
        font-size: 1.3vw;
    }
    @media screen and (max-width: 768px){
        font-size: 3.5vw;
        bottom: 80%;
        position: static;
        display: block;
        transform: none;
    }

`;

const LabelAndInputWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    @media screen and (max-width: 1024px){
        
    }
    @media screen and (max-width: 768px){
        flex-direction: column;
        align-items: flex-start;
    }

`

export const InputWithLabel = ({ text, type, htmlFor, placeholder, onChange, invalid, value, children, className, errorMessage, maxlength}) => (
<StyledLabel htmlFor={htmlFor} className={className}>
    <LabelAndInputWrapper>
        <StyledSpan>
            {text}
        </StyledSpan>
        <StyledInput tabIndex="0" onChange={onChange} type={type} id={htmlFor} name={htmlFor} placeholder={placeholder} value={value} maxLength={maxlength}/>
        {invalid && <InvalidMessage>{errorMessage}</InvalidMessage>}
    </LabelAndInputWrapper>
    <div>
        {children}
    </div>
</StyledLabel>
)

const StyledSelect = styled.select`
    color: #FFFFFF;
    font-size: 0.9vw;
    margin-left: 0.5em;
    padding: 0.5em 0 0.5em 0.5em;
    
    height: 2.5em;

    background-color: transparent;

    /* border: none; */
    border: 1px solid #FFFFFF;
    outline: none;

    width: 100%;

    &:focus{
        border: 1px solid ${colors.turquoise};
        color: ${colors.turquoise};
    }
    option{
        color: black;
    }
    @media screen and (max-width: 1024px){
        font-size: 1.5vw;
    }
    @media screen and (max-width: 768px){
        font-size: 5vw; 
        margin-left: 0;
        margin-top: 0.5em;
    }

`;

export const DropDownWithLabel = ({ text, htmlFor, onChange, items, value }) => (
    <StyledLabel htmlFor={htmlFor}>
        <StyledSpan>
            {text}
        </StyledSpan>
        <DropDown id={htmlFor} name={htmlFor} items={items} value={value} onChange={onChange} />
    </StyledLabel>
)

const DropDown = ({ id, name, items, onChange, value }) => (
    <StyledSelect name={name} id={id} value={value} onChange={onChange}>
        {items.map((item, index) => <option key={index} value={item}>{item}</option>)}
    </StyledSelect>
)