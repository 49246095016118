import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { connect } from "react-redux";

const PROGRESS_ARRAY = [
    { menuId: 1, progress: 3, text: "1% Complete" },
    { menuId: 2, progress: 10, text: "10% Complete" },
    { menuId: 3, progress: 20, text: "20% Complete" },
    { menuId: 4, progress: 30, text: "30% Complete" },
    { menuId: 5, progress: 40, text: "40% Complete" },
    { menuId: 6, progress: 50, text: "50% Complete" },
    { menuId: 7, progress: 60, text: "60% Complete" },
    { menuId: 8, progress: 70, text: "70% Complete" },
    { menuId: 9, progress: 80, text: "80% Complete" },
    { menuId: 10, progress: 90, text: "90% Complete" },
    { menuId: 11, progress: 98, text: "99% Complete" },
];

class ProgressBar extends React.Component {

    static propTypes = {
        navMenuState: PropTypes.shape({
            selectedMenuItemId: PropTypes.number.isRequired
        })
    }

    render() {
        const { selectedMenuItemId } = this.props.navmenuState;
        const progressInfo = PROGRESS_ARRAY.filter(x => x.menuId === selectedMenuItemId)[0];
        return (
            <ProgressBar2 progress={progressInfo.progress} text={progressInfo.text} />
        );
    }
}

const mapStateToProps = (state) => ({
    navmenuState: state.navmenu
})

export default connect(mapStateToProps, {})(ProgressBar);

export const ProgressBar2 = ({ progress, text, className }) => (
    <Bar className={className}>
        <Progress progress={progress} text={text} />
    </Bar>
)

const Bar = styled.div`
    width: 110%;
    margin: 0 -5%;
    height: 4px;
    background-color: rgba(59,91,126,0.7);
    background-color: #2C3E51;
    @media screen and (max-width: 1024px){
        margin: 0;
    }
    @media screen and (max-width: 768px){
    }
`;

const Progress = styled.div`
    width: ${props => props.progress + '%'};
    height: 4px;
    background-color: #92CAB1;
    box-shadow: 0 0 1px 0px #92CAB1;
    transition: 0.15s width ease-in-out;
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    &::before{
        content: '';
        display: block;
        width: 12px;
        height: 12px;
        background-color: #92CAB1;
        border: 1px solid #FFFFFF;
        border-radius: 50%;
    }
    &::after{
        content: '${props => props.text}';
        display: block;
        position: absolute;
        transform: translate(${props => props.progress > 90 ? '-25%' : '125%'},-120%);
        white-space: nowrap;
        font-size: 10px;
        color: rgba(255,255,255, 0.8);
        transition: 0.15s transform ease-in-out;
    }
    @media screen and (max-width: 1024px){

    }
    @media screen and (max-width: 768px){

    }
`;