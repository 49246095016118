import * as React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import PropTypes from "prop-types";
import colors from "../Slides/__common__/colors";
import Arrow from "../resources/arrow.svg";
import WarningMessage from "../Slides/__common__/WarningMessage";
import ErrorMessage from "../Slides/__common__/ErrorMessage";
import { findFirstFocusableElement } from '../Slides/__common__/firstFocusableHelper';

const StyledErrorMessage = styled(ErrorMessage)`
    position: absolute;
    top: calc(-10px - 0.8vw);

    @media screen and (max-width: 1024px){
        top: calc(-10px - 1.5vw);
    }

    @media screen and (max-width: 768px){
        top: calc(-10px - 4vw);
    }
`;

const StyledWarningMessage = styled(WarningMessage)`
    position: absolute;
    top: calc(-10px - 0.8vw);

    @media screen and (max-width: 1024px){
        top: calc(-10px - 1.5vw);
    }

    @media screen and (max-width: 768px){
        top: calc(-10px - 6vw);
    }
`;

const Wrapper = styled.div`
    ${'' /* height: 10%; */}
    display: flex;
    justify-content: center;
    position: relative;
    flex-wrap: wrap;

    @media screen and (max-width: 1024px){
        
    }
    @media screen and (max-width: 768px){
        align-items: center;
        background-color: ${colors.background};
        z-index: 1000;
        width: 100%;
        position: fixed;
        left: 0;
        bottom: 0;
        padding-bottom: 5vw;
        padding-top: 1vw;
    }
`;

export const StyledButton = styled.button`
    border: 1px solid #FFFFFF;
    color: #FFFFFF;
    text-transform: uppercase;
    text-decoration: none;
    width: 9.75vw;
    height: 2.3vw;
    transition: 0.15s transform ease-in-out;
    
    &:hover{
        transform: scale(1.05);
    }

    &:focus{
        transform: scale(1.05);
    }

    &::-moz-focus-inner {
        border: 0;
    }

    background-color: transparent;
    outline: none;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;


    font-size: 1.05vw;
    margin-right: ${props => props.left && '5%'};
    margin-left: ${props => !props.left && '5%'};

    span{
        font-weight: 200;
        margin-left: 10%;        
        margin-right: ${props => props.left && '5%'};
        
    }
    img{
        width: 12px;
        transform: ${props => props.left && 'rotate(180deg)'};
        margin-left: ${props => !props.left && '20%'};
        margin-right: ${props => props.left && '5%'};
    }

    @media screen and (max-width: 1024px){
        font-size: 1.65vw;
        width: 15vw;
        height: 3.5vw;
        
        margin-right: ${props => props.left && '3%'};
        margin-left: ${props => !props.left && '3%'};

        img{
            width: 10px;
        }

        &:hover{
            transform: scale(1.03);
        }
    }
    
    @media screen and (max-width: 768px){
        font-size: 4.45vw;
        width: 40vw;
        height: 11vw;
        
        margin-right: ${props => props.left && '2%'};
        margin-left: ${props => !props.left && '2%'};

        img{
            width: 10px;
        }
    }
`;

const NavigationPanel = ({ onGoBackward, onGoForward, content, selectedMenuItemId, showDefaultValuesWarning, defaultValuesWarningMessage, isValidated, validationErrorMessage, firstFocusableElement }) => {
    let warning;
    let error;

    const handleTabPress = (event) =>{
        if (event.key === "Tab" || event.keycode === 9){
            event.preventDefault();
            if(!firstFocusableElement){
                firstFocusableElement = findFirstFocusableElement();
            }

            firstFocusableElement.focus();
        }
    }

    if (showDefaultValuesWarning === true) {
        warning = <StyledWarningMessage message={defaultValuesWarningMessage}/>
    }

    if (!isValidated) {
        error = <StyledErrorMessage message={validationErrorMessage}></StyledErrorMessage>
    }

    return (
        <Wrapper>
            {warning}
            {error}
            <StyledButton tabIndex="0" onClick={onGoBackward} left>
                <img src={Arrow} alt="" />
                <span>{content.previous}</span>
            </StyledButton>
            <StyledButton tabIndex="0" onClick={onGoForward} onKeyDown={handleTabPress}>
                <span>{content.next}</span>
                <img src={Arrow} alt="" />
            </StyledButton>
        </Wrapper>
    )
}

NavigationPanel.propTypes = {
    onGoBackward: PropTypes.func.isRequired,
    onGoForward: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    content: {
        previous: state.applyContent.umb_dictionary.previous,
        next: state.applyContent.umb_dictionary.next
    },
    selectedMenuItemId: state.navmenu.selectedMenuItemId
})
export default connect(mapStateToProps, {})(NavigationPanel);