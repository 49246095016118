import styled from "styled-components";

export const Wrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-top: 5%;
    
    @media screen and (max-width: 1024px){
        
    }

    @media screen and (max-width: 768px){
        width: 50%;
        height: 85%;
        margin-top: 0;
        flex-direction: column;
    }
`;