import React from "react";
import styled, { keyframes } from "styled-components";

const spin = keyframes`
  0%{
    transform: rotate(0deg);
  }
  100%{
    transform: rotate(360deg);
  }
`;

const Spin = styled.span`
  /* border: 1px solid #a6a6a6; */
  border: 2px solid transparent;
  border-top-color: #fff;
  border-right-color: #fff;
  border-radius: 50%;

  height: 0.8em;
  width: 0.8em;

  animation: ${spin} 0.9s infinite linear;
  display: inline-block;
`;


export default class Spinner extends React.Component {

    render() {
        return (
            <Spin></Spin>
        )
    }
}