import React from "react";
import styled from 'styled-components';

const Div = styled.div`
	color:	#FFFF00;
    font-size: 0.8vw;
	text-align: center;
	width: 100%;
	padding: 0 30px;
    p{
          margin: 0;
    }

    @media screen and (max-width: 1024px){
        font-size: 1.5vw;
    }

    @media screen and (max-width: 768px){
        font-size: 4vw;
    }
`;

class WarningMessage extends React.Component {
	render() {
		return (
			<Div className={this.props.className}>
				<p>{this.props.message}</p>
			</Div>
		)
	}
}

export default WarningMessage;