import * as React from "react";
import styled from "styled-components";
import QuestionHeader, { H4 } from "../__common__/styled-components";

import { connect } from 'react-redux';
import { doSendSlide3Data } from '../../../actions';
import { doSetAllSlidersWereTouched } from '../../../actions/allSlidersWereTouched';
import { doSetSlideWarningShown } from '../../../actions/slideWarningShown';
import { allSlidersTouched, handleSliderTouch, generateSliderState } from "../__common__/RangeInput/sliderParentCallbacks";
import { findFirstFocusableElement } from '../__common__/firstFocusableHelper';
import BaseSlide from "../BaseSlide";
import RangeInput from "../__common__/RangeInput";
import "./polyfills.js";
import {Config} from "../../../Config";
const ExRangeInput = styled(RangeInput)`
    border-radius: 0;
    
    & > div:first-child{
        border-radius: 0;
    }
    
    & .degrees-container{
        width: 100%;
        // transform: translate(0,5.1vw);
        
        & div {
            text-align: center;
            font-size: 0.75vw;
            position: relative;
            padding: 0 0.1vw;
            width: 100%;
            
            & span{
                position: absolute;
                top: 0;
                left: -10%;
                width: 120%;
            }
            &:first-child{
                transform: translateX(-50%);
            }
            &:last-child{
                transform: translateX(50%);
            }
        }
    }
    @media screen and (max-width: 1024px){
        & .degrees-container{
            // transform: translate(0,6.1vw);
            & div{
                font-size: 1.1vw;
            }
        }  
    }
    @media screen and (max-width: 768px){
        margin-bottom: 12vw;
        margin-top: 25vw;
        
        & .degrees-container{
            // transform: translate(4%,-22.5vw);
            & div{
                font-size: 2.65vw;
        
                & span{
                    position: absolute;
                    top: 0;
                    left: -24%;
                    width: 135%;
                }
            }
        }
    }
`;

class Slide3 extends React.Component {

    state = {
        proximity: 0,
        slidersTouched: {
        },
        showDefaultValuesWarning: false,
        menuItems: [],
        allSidersWereTouched: false,
        warningMessageShown: false
    }

    handleSliderTouch = handleSliderTouch.bind(this);
    allSlidersTouched = allSlidersTouched.bind(this, 3);
    generateSliderState = generateSliderState.bind(this);
    findFirstFocusableElement = findFirstFocusableElement;

    static getDerivedStateFromProps(nextProps) {
        return {
            proximity: nextProps.proximity,
            menuItems: nextProps.menuItems,
            allSidersWereTouched: nextProps.allSidersWereTouched.allSidersWereTouched,
            warningMessageShown: nextProps.warningMessageShown.warningMessageShown
        }
    }

    handleValueChange = (value) => {
        this.allSlidersTouched();
        this.props.doSendSlide3Data(value);
    }

    handleGoForward = () => {
        if (this.allSlidersTouched() || this.state.allSidersWereTouched || this.state.warningMessageShown) {
            return true;
        } else {
            this.setState({
                showDefaultValuesWarning: true,
                warningMessageShown: true
            });
            this.props.doSetSlideWarningShown(3, { warningMessageShown: true });
            return false;
        }
    }

    componentDidMount() {
        this.firstFocusableElement = this.findFirstFocusableElement();

        if (window.gtag) {
            window.gtag('config', Config.GOOGLE_ANALYTICS, {
                'page_title' : 'Slide 3',
                'page_path': `/assessment/3`
            });
        }
    }

    render() {
        const { proximity } = this.state;

        const degrees = this.props.content.decisionMaker;

        const { header, description, optional_headline } = this.props.content;
        const slideData = {
            id: 3,
            title: header,
            text: description
        }

        return (
            <BaseSlide {...slideData} onGoForward={this.handleGoForward} hasToBeCenter={true} showDefaultValuesWarning={this.state.showDefaultValuesWarning} defaultValuesWarningMessage="The slider value is set to 0. If the value is correct click NEXT to continue the assessment." firstFocusableElement={this.firstFocusableElement}>
                <QuestionHeader>
                    <H4 flexBasis="45%">{optional_headline}</H4>
                </QuestionHeader>
                <div className="range-input-wrapper">
                    <ExRangeInput type="degree"
                                  value={proximity}
                                  onValueChange={this.handleValueChange}
                                  degrees={degrees}
                                  handleSliderTouch={this.handleSliderTouch}
                                  name="proximitySlider"
                                  allSlidersWereTouched={this.state.allSidersWereTouched}
                                  slideWarningMessageShown={this.state.warningMessageShown}
                                  generateSliderState={this.generateSliderState} />
                </div>
            </BaseSlide>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        proximity: state.applySlidesData.proximity,
        allSidersWereTouched: state.applyAllSlidersWereTouched[2],
        warningMessageShown: state.applySlideWarningShown[2],
        content: {
            ...state.applyContent.steps[2],
            decisionMaker: [
                state.applyContent.umb_dictionary.decisionmaker_lowest,
                state.applyContent.umb_dictionary.decisionmaker_medium,
                state.applyContent.umb_dictionary.decisionmaker_highest,
            ]
        }
    }
}

const mapDispatchToProps = { doSendSlide3Data, doSetAllSlidersWereTouched, doSetSlideWarningShown };
export default connect(mapStateToProps, mapDispatchToProps)(Slide3);