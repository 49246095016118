import React from "react";
import PropTypes from "prop-types";
import { Circle, CircleTooltip, DefaultStateInfo } from "./RangeInputStyles";

const CircleWithTooltip = ({ onKeyDown, onKeyUp, onMouseDown, onMouseUp, onTouchStart, onTouchEnd, translateX, value, type, isMouseDown, isTouched }) => {
    const factor = window.innerWidth < 768 ? 7 : 6;
    const styles = {
        left: `${translateX - factor}px`
    };
    const text = value + (type === 'percent' ? '%' : '');
    let defaultStateInfo = !isTouched ? <DefaultStateInfo>Slide to set value</DefaultStateInfo> : null;
    return (
        <Circle tabIndex="0" onMouseDown={onMouseDown} onMouseUp={onMouseUp} onKeyDown={onKeyDown} onKeyUp={onKeyUp} onTouchStart={onTouchStart} onTouchEnd={onTouchEnd} style={styles} className={isMouseDown ? 'active' : null}>
            <CircleTooltip className={!isTouched ? 'untouched' : null}>{text}</CircleTooltip>
            {defaultStateInfo}
        </Circle>
    )
}

export default CircleWithTooltip;

CircleWithTooltip.propTypes = {
    onMouseDown: PropTypes.func.isRequired,
    translateX: PropTypes.number.isRequired,
    value: PropTypes.any.isRequired,
    isMouseDown: PropTypes.bool
}