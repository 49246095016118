import React from "react";
import styled from "styled-components";
import { Route, Switch } from "react-router-dom";

import NavMenu from '../NavMenu';
import routes from "./routes";

import { TransitionGroup } from 'react-transition-group';


const Wrapper = styled.div`
    flex-basis: 85%;
    display: flex;
    margin: 0 auto;
    height: 80%;
    transition: 0.1s margin ease-in-out;

    @media screen and (max-width: 1024px){
    }
    @media screen and (max-width: 768px){
        flex-basis: 100%;
        width: 100%;
        height: 100%;
    }
`;

const StyledMain = styled.main`
    flex-basis: 75%;
    height: 100%;
    display: flex;
    margin-right: 10%;

    @media screen and (max-width: 1024px){
        margin-right: 0;
    }

    @media screen and (max-width: 768px){
        flex-basis: 100%;
        width: 100%;
    }
`;

const StyledTransitionGroup = styled(TransitionGroup)`
    width: 100%;
    @media screen and (max-width: 1024px){

    }

    @media screen and (max-width: 768px){
        /* width: 100%; */
    }
`;

// const StyledCSSTransition = styled(CSSTransition)`
//     min-height: 100%;
//
// `;

const AppWrapper = ({ location }) => {
    return (
        <Wrapper id="app-wrapper">
            <NavMenu />
            <StyledMain>
                <StyledTransitionGroup>
                    {/* <StyledCSSTransition key={location.key} timeout={200} classNames="blas"> */}
                    <Switch location={location}>
                        {routes.map(route =>
                            <Route key={route.id} exact={route.exact} path={route.path} component={route.component} />
                        )}
                    </Switch>
                    {/* </StyledCSSTransition> */}
                </StyledTransitionGroup>
            </StyledMain>
        </Wrapper>
    )
}
export default AppWrapper;