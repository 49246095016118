import uuid from "uuid-v4";

export default class TeamMember {
    constructor(name = '', placeholder = '') {
        this.id = uuid();
        this.name = name;
        this.untrimmed = '';
        this.competence = '';
        this.yearlySalary = '';
        this.months = '';
        this.placeholder = placeholder;
        this.hasChangedManualInput = false;
    }
}
