import React from "react";
import styled from "styled-components";

import LogoHeader from "./LogoHeader";
import AppWrapper from "./AppWrapper";
import Footer from "./Footer";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
    @media screen and (max-width: 1024px){
        
    }
    @media screen and (max-width: 768px){
        min-height: 100%;
        ${'' /* height: auto; */}

    }

`;

const PageLayout = ({ location }) => {
    return (
        <Wrapper>
            <LogoHeader />
            <AppWrapper location={location} />
            <Footer />
        </Wrapper>
    )
}
export default PageLayout;

