import styled from "styled-components";

export const Table = styled.div`
    width: 95%;
    margin: 0 auto;
    
    margin-top: 1vw;

    @media screen and (max-width: 1024px){
        
    }
    @media screen and (max-width: 768px){
        display: none;
    }

`;


export const Row = styled.div`
    display: flex;
    height: 2.5em;  
    
    &:last-child {
       margin-bottom: 2.5em;  
    }
    
    & > div{
        width: 31.5%;
    }
    
    @media screen and (max-width: 1024px){
        height: 3em;
    }
    
    @media screen and (max-width: 768px){
        flex-flow: row wrap;
        width: 99%;
        height: auto;
        
        & > div{
            flex: 1 100%;
        }
        
        & > div:first-child{
            display: none;
        }
    }
`;

export const Cell = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    
    @media screen and (max-width: 768px){
        margin-top: 2rem;
        flex-direction: column;
        
        & > div{
            & > span{
                font-size: 8.5vw;
                min-width: 21vw;
            }
        }
    }

`;
export const MobileCellText = styled.span`
    display: none;

    font-size: 5vw;
    margin-top: 0.25em;
    margin-bottom: 0.8em;
    
    @media screen and (max-width: 768px){
        display: flex;
        position: relative;
    }

`;

export const TableHeader = styled.div`
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 1em;
    font-size: 1vw;
    white-space: nowrap;
    
    & > div {
        width: 31.5%;
        text-align: center;
    }
    
    @media screen and (max-width: 1024px){
        font-size: 1.65vw;
        margin-top: 2em;
        white-space: normal;
    }
    
    @media screen and (max-width: 768px){

    }
`;

export const SalarySubText = styled.span`
    font-size: 0.7vw;
    position: absolute;
    bottom: -1vw;
    
    @media screen and (max-width: 768px){
        font-size: 4vw;
        bottom: -1em;
        width: 100%;
        display: inline-block;
        text-align: center;
    }
`;
