import * as React from "react";
import styled from "styled-components";
import BaseSlide from "../BaseSlide";

import { connect } from 'react-redux';
import { doSendSlide2Data } from '../../../actions';

import ScrollBarArea from "../__common__/ScrollBarArea";

import TableRow from "./TableRow";
import { Table, TableHeader, Cell } from "./styles";
import QuestionHeader, { H4 } from "../__common__/styled-components";
import InfoModal from "../InfoModal";
import { doGoForward, doGoBackward } from "../../../actions";
import { findFirstFocusableElement } from '../__common__/firstFocusableHelper';

import SpeechBubble from "../../resources/speech-bubble.svg";
import StatementsNavigation from "../__common__/StatementsNavigation";
import { NameWrapper } from "../__common__/NameWrapper";
import { Object } from "core-js";
import {Config} from "../../../Config";

const StyledScrollBarArea = styled(ScrollBarArea)`
    height: 40vh !important;
    font-size: 0.9vw;
    
    @media screen and (max-width: 1024px){
        font-size: 1.6vw;
        
    }
    @media screen and (max-width: 768px) {
        height: 50vh !important;
    }

`;


const StyledQuestionHeader = styled(QuestionHeader)`
    display: none;
    @media screen and (max-width: 768px){
        display: flex;
    }
`;

const MobileContainer = styled.div`
    display: none;
    
    @media screen and (max-width: 768px){
        display: flex;
        flex-direction: column;
        width: 75%
        margin: 0 auto;
        padding-bottom: 25vw;
    }
`;

class Slide2 extends React.Component {

    state = {
        teamMembers: [],
        selectedMemberIndex: 0,
        modalVisible: false,
        isValidated: true,
        nextPressed: false,
        showDefaultValuesWarning: false,
        warningMessageShown: false
    };

    findFirstFocusableElement = findFirstFocusableElement;
    // generated manually below

    // on mount check if mobile and hide modal if true
    componentDidMount() {
        this.firstFocusableElement = this.findFirstFocusableElement();

        if (window.gtag) {
            window.gtag('config', Config.GOOGLE_ANALYTICS, {
                'page_title' : 'Slide 2',
                'page_path': `/assessment/2`
            });
        }
    }

    static getDerivedStateFromProps(nextProps/*, prevState*/) {
        const { teamMembers } = nextProps;
        return {
            teamMembers: teamMembers || []
        }
    }

    handleMemberChange = (newMember) => {
        const members = this.state.teamMembers.map(curr => {
            if (curr.id === newMember.id) {
                curr.competence = newMember.competence;
                curr.yearlySalary = newMember.yearlySalary;
                curr.hasChangedManualInput = newMember.hasChangedManualInput;
            }
            return curr;
        });
        const isAnyEmpty = members.some(item => Slide2.isEmpty(item.yearlySalary) || Slide2.isEmpty(item.competence));

        this.setState({
            isValidated: !isAnyEmpty
        });

        this.props.doSendSlide2Data(members);
    };

    static isEmpty = (value) => !value;

    static isEmptyMember(member) {
        return Slide2.isEmpty(member.yearlySalary) || Slide2.isEmpty(member.competence);
    }

    handleGoForward = () => {
        const isAnyEmpty = this.state.teamMembers.some(Slide2.isEmptyMember);

        this.setState({
            isValidated: !isAnyEmpty,
            nextPressed: true
        });

        return !isAnyEmpty;
    };

    handleSelectChange = (e) => {
        const index = e.target.value;
        this.setState({ selectedMemberIndex: index });
    };

    showInfoModal = () => {
        const appWrapper = document.getElementById('app-wrapper');
        this.setState(prevState => ({ modalVisible: !prevState.modalVisible }), () => {
            if (window.innerWidth < 1024 && window.innerWidth > 768) {
                if (this.state.modalVisible) {
                    appWrapper.style.margin = '0 auto 0 0';
                } else {
                    appWrapper.style.margin = '0 auto';

                }
            }

        });
    };

    handleModalClose = () => {
        this.setState({ modalVisible: false })
    };

    handleNextIndividuals = () => {
        const { selectedMemberIndex } = this.state;
        const memberCount = this.state.teamMembers.length;

        window.scrollTo(0, 0);

        this.setState({
            nextPressed: true
        });

        if (Slide2.isEmptyMember(this.state.teamMembers[selectedMemberIndex])) {
            return false;
        }

        if (selectedMemberIndex === memberCount - 1) {
            this.handleGoForward();
            this.props.doGoForward();
            const { selectedMenuItemId } = this.props;
            const stepId = selectedMenuItemId + 1;
            this.props.history.push('/assessment/' + stepId);
            return;
        }

        this.setState(prevState => ({
            selectedMemberIndex: prevState.selectedMemberIndex + 1,
            nextPressed: false // reset before next member
        }));
    };

    handlePrevIndividuals = () => {
        const { selectedMemberIndex } = this.state;
        window.scrollTo(0, 0);
        if (selectedMemberIndex === 0) {
            const { selectedMenuItemId } = this.props;
            this.props.doGoBackward();
            this.props.history.push(`/assessment/${selectedMenuItemId - 1}`);
            return;
        }
        this.setState(prevState => ({ selectedMemberIndex: prevState.selectedMemberIndex - 1 }));
    };

    render() {
        const { teamMembers, selectedMemberIndex, modalVisible, isValidated, nextPressed } = this.state;
        const memberForDropdown = teamMembers[selectedMemberIndex];
        const { header, description, tableContent, legend } = this.props.content;
        const isMemberValidated = !Slide2.isEmptyMember(teamMembers[selectedMemberIndex]);
        const slideData = {
            id: 2,
            title: header,
            text: description
        };
        
        const infoItems = Object.values(legend);
        return (
            <BaseSlide isValidated={!nextPressed || isValidated} nextPressed={nextPressed} validationErrorMessage="Please fill out all fields." {...slideData} onGoForward={this.handleGoForward} hasToBeCenter={false} firstFocusableElement={this.firstFocusableElement}>
                <StyledQuestionHeader>
                    <H4 flexBasis="45%" style={{ textAlign: 'left' }}>How would you assess the competence of the individuals if measured on 3 parameters: Current Competence, Estimated Potential and Estimated yearly salary?</H4>
                </StyledQuestionHeader>
                <Table>
                    <TableHeader>
                        <Cell>{tableContent.individuals}</Cell>
                        <Cell>{tableContent.yearlySalary}</Cell>
                        <Cell>{tableContent.currentCompetence}</Cell>
                        <Img src={SpeechBubble} alt="speech bubble" onClick={this.showInfoModal} />
                    </TableHeader>
                    <StyledScrollBarArea>
                        {teamMembers.map(member => <TableRow key={member.id} member={member} onMemberChange={this.handleMemberChange} tableContent={tableContent} />)}
                    </StyledScrollBarArea>
                    <InfoModal items={infoItems} visible={modalVisible} onClose={this.handleModalClose} />
                </Table>

                <MobileContainer>
                    <NameWrapper><span>{memberForDropdown.name || 'Individual'}</span></NameWrapper>
                    {
                        teamMembers.map((member, index) => index === selectedMemberIndex && <TableRow key={member.id} index={index} member={memberForDropdown} onMemberChange={this.handleMemberChange} showModal={this.showInfoModal} tableContent={tableContent} />)
                    }
                    <InfoModal items={infoItems} visible={modalVisible} onClose={this.handleModalClose} />


                    <StatementsNavigation isValidated={!nextPressed || isMemberValidated}
                                          validationErrorMessage="Please fill out missing fields"
                                          onGoForward={this.handleNextIndividuals}
                                          onGoBackward={this.handlePrevIndividuals} />
                </MobileContainer>

            </BaseSlide>
        )
    }
}

export const Img = styled.img`
    position: relative;
    top: -50%;
    cursor: pointer;
    transition: 0.1s transform ease-in-out;
    width: 18px;
    display: inline-block;
    
    &:hover{
        transform: scale(1.1) ;
    }

    @media screen and (max-width: 1024px){
        transform: translate(150%, -25%);
        left: -30px;
        &:hover{
            transform: scale(1.05) translate(150%, -25%) ;
        }

    }

    @media screen and (max-width: 768px){
        left: -20px;
    }
`;

const mapStateToProps = ({ applySlidesData, applyAllSlidersWereTouched, applySlideWarningShown, applyContent, navmenu }) => {
    return {
        teamMembers: applySlidesData.teamMembers,
        content: {
            ...applyContent.steps[1],
            tableContent: {
                individuals: applyContent.umb_dictionary.individuals,
                yearlySalary: applyContent.umb_dictionary.estimated_yearly_salary1,
                yearlySalarySub: applyContent.umb_dictionary.estimated_yearly_salary2,
                currentCompetence: applyContent.umb_dictionary.current_competence
            },
            legend: applyContent.steps[0].legend
        },
        selectedMenuItemId: navmenu.selectedMenuItemId
    }
}

const mapDispatchToProps = { doSendSlide2Data, doGoForward, doGoBackward };
export default connect(mapStateToProps, mapDispatchToProps)(Slide2);
