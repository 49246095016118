import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { doSetVisited, doGoForward, doGoBackward } from "../../actions";

import { Container } from "./__common__/styled-components";

import { SlideHeader } from "./SlideHeader";

import NavigationPanel from '../NavigationPanel';

class BaseSlide extends React.Component {

    static propTypes = {
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
        onGoForward: PropTypes.func.isRequired,
        nextPressed: PropTypes.bool
    }

    componentDidMount() {
        this.props.doSetVisited(this.props.id);
        // const minHeight = this.baseSlideRef.style.minHeight;
        // this.baseSlideRef.style.height = 'min-content';
    }

    handleGoForward = () => {
        const redirect = this.props.onGoForward();
        window.scrollTo(0, 0);
        if (typeof redirect === 'undefined') throw new Error('You have to return value');
        if (redirect) {
            this.props.doGoForward();
            const { selectedMenuItemId } = this.props;

            let nextPage;
            if (this.props.nextLink) {
                nextPage = this.props.nextLink;
            } else {
                nextPage = selectedMenuItemId + 1;
            }

            this.props.history.push(`/assessment/${nextPage}`);
        }
    }

    handleGoBackward = () => {
        const {selectedMenuItemId} = this.props;

        if (selectedMenuItemId === 1) {
            this.props.history.push('/');
            return;
        }


        let redirect = true;

        if (this.props.onGoBackward) {
            redirect = this.props.onGoBackward();
        }
        if (typeof redirect === 'undefined') throw new Error('You have to return value');
        if (redirect) {

            this.props.doGoBackward();

            let backPage;
            if (this.props.backLink) {
                backPage = this.props.backLink;
            } else {
                backPage = this.props.selectedMenuItemId - 1;
            }

            this.props.history.push(`/assessment/${backPage}`);
        }
    };

    render() {
        const { title, text, children, hasToBeCenter, hasLimitedHeight, className, selectedMenuItemId, backLink, nextPressed} = this.props;
        const isMobile = window.innerWidth <= 768;

        return (
            <Container className={className}
                       selectedMenuItemId={selectedMenuItemId}>
                <SlideHeader title={title} text={text} />
                <SlideWrapper hasToBeCenter={hasToBeCenter}
                              hasLimitedHeight={hasLimitedHeight}
                              selectedMenuItemId={selectedMenuItemId}
                              backLink={backLink}>
                    <SlideContentWrapper className={nextPressed ? 'next-pressed' : null}>
                        {children}
                    </SlideContentWrapper>
                </SlideWrapper>
                {
                    (selectedMenuItemId === 2 ||
                        selectedMenuItemId === 5 ||
                        selectedMenuItemId === 7) && isMobile ? null : <NavigationPanel onGoBackward={this.handleGoBackward} onGoForward={this.handleGoForward} showDefaultValuesWarning={this.props.showDefaultValuesWarning} defaultValuesWarningMessage={this.props.defaultValuesWarningMessage} isValidated={this.props.isValidated} validationErrorMessage={this.props.validationErrorMessage} firstFocusableElement={this.props.firstFocusableElement}/>
                }
            </Container>
        )
    }

}

const SlideWrapper = styled.div`
    height: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
       
    position: ${props => props.selectedMenuItemId === 2 ? 'static': 'relative'};
    
    @media screen and (max-width: 768px){
        height: 70%;
        width: 100%;
        padding: 0;
        flex-flow: row wrap;
        justify-content: center;
        align-items: flex-start;
        align-content: flex-start;
        margin-bottom: 5vh;
    }
`;

const SlideContentWrapper = styled.div`
    width: 100%;
    max-height: 100%;
`;

const mapStateToProps = (state) => ({
    selectedMenuItemId: state.navmenu.selectedMenuItemId,
    slide5_selectedStatement: state.navmenu.slide5_selectedStatement
});

const mapDispatchToProps = { doSetVisited, doGoForward, doGoBackward };

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BaseSlide));


