export const ActionTypes = {
    SET_SLIDE_WARNING_SHOWN: 'SET_SLIDE_WARNING_SHOWN',
}

export const doSetSlideWarningShown = (id, warningMessageShown) => ({
    type: ActionTypes.SET_SLIDE_WARNING_SHOWN,
    payload: {
        id,
        warningMessageShown
    }
})
