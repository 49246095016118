import React from "react";
import ReactDOM from 'react-dom';
import styled from "styled-components";
import { withRouter } from 'react-router-dom'

import { Wrapper, StyledNav, List, BoundaryLine, SlideNumber } from "./styles";

import { connect } from 'react-redux';
import { selectMenuItem } from '../../actions';

import NavLink from "../NavLink";

const EmptyBlockTop = styled.div`
    height: 25%;
    @media screen and (max-width: 1024px){
        height: 20%;
        
    }
    @media screen and (max-width: 768px){
    }

`;
const EmptyBlockBottom = styled.div`
    height: 10%;

    @media screen and (max-width: 1024px){
    }
    @media screen and (max-width: 768px){
    }

`;
class NavMenu extends React.Component {

    state = {
        minPosition: 0,
        maxPosition: 0,
        offsetTop: null,
        bottom: 0
    }

    constructor() {
        super();
        this.list = React.createRef();
        this.boundaryLine = React.createRef();
    }

    componentDidMount() {
        const minPosition = this.boundaryLine.current.offsetTop;
        const maxPosition = this.boundaryLine.current.offsetTop + this.boundaryLine.current.offsetHeight;
        const offsetTop = this.list.current.offsetTop;
        this.setState({ minPosition, maxPosition, offsetTop });
    }

    static getDerivedStateFromProps(nextProps) {
        // const { selectedMenuItemId } = nextProps.navmenuState;
        const selectedMenuItemId = nextProps.match.params['id'];
        const isTablet = window.innerWidth <= 1024;
        const distance = isTablet ? 25 : 29;
        return {
            bottom: (selectedMenuItemId - 1) * distance
        }
    }
    handleMenuSelect = (id) => this.props.selectMenuItem(id);

    _refreshListPosition = () => this.setState({ offsetTop: this.list.offsetTop });

    render() {
        const { menuItems } = this.props;
        const selectedMenuItemId = this.props.match.params['id'];
        const subpageId = this.props.match.params['subpage'] || 0;

        return (
            <Wrapper>
                <EmptyBlockTop />
                <StyledNav>
                    <List ref={this.list}
                        bottom={this.state.bottom}
                        innerRef={x => this.list = x}
                        onTransitionEnd={this._refreshListPosition}>
                        {menuItems.map(({ path, id, exact, tooltip, isVisited }, index) => {
                            const isActive = isVisited;
                            return (
                                <NavLink
                                    key={id}
                                    to={path}
                                    id={id}
                                    exact={exact}
                                    tooltip={tooltip}
                                    isActive={isActive}
                                    selectedMenuItemId={selectedMenuItemId}
                                    onMenuSelect={this.handleMenuSelect}
                                    minPosition={this.state.minPosition}
                                    maxPosition={this.state.maxPosition}
                                    parentPosition={this.state.offsetTop}
                                />

                            )
                        })}
                    </List>
                    <BoundaryLine ref={this.boundaryLine} innerRef={x => this.boundaryLine = x} />
                    <SlideNumber>{selectedMenuItemId}{subpageId ? '.' + subpageId : ''}</SlideNumber>
                </StyledNav>
                <EmptyBlockBottom />
            </Wrapper>
        )
    }
}
const getMenuItems = (state) => state.navmenu.menuItems;

const mapStateToProps = (state) => {
    return {
        navmenuState: state.navmenu,
        selectedMenuItemId: state.navmenu.selectedMenuItemId,
        menuItems: getMenuItems(state),
        slidesState: state.applySlidesData
    }
}

const mapDispatchToProps = { selectMenuItem };
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavMenu));