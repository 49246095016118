import styled from "styled-components";
import colors from "../colors";

export const Wrapper = styled.div`
    height: 0.4vw;
    margin: 4vw 0vw 0 0;
    background-color: ${colors.progress};

    border-radius: 25px;

    display: flex;
    align-items: center;

    cursor: pointer;

    position: relative;
    @media screen and (max-width: 1024px){
        height: 0.6vw;
    }

    @media screen and (max-width: 768px){
        height: 2vw;
        margin: 4vw 0 20vw 0;

    }
`;

const Button = styled.div`
    display: none;
    @media screen and (max-width: 1024px){

    }

    @media screen and (max-width: 768px){
        position: absolute;
        font-size: 9vw;
        width: 6vw;
        height: 6vw;
        color: ${colors.turquoise};
        
        &::selection{
            background-color:transparent;
        }
        
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

export const IncrementButton = styled(Button)`
    right: -15%;
    bottom: -50%;

`;
export const DecrementButton = styled(Button)`
    left: -15%;
    bottom: 90%;
`;

export const Progress = styled.div`
    height: 0.4vw;

    border-radius: 25px;

    background-color: ${colors.turquoise};

    position: absolute;
    left: 0;
    top: 0;

    transition: width 100ms ease-out;
    
    &.active {        
        transition: none;    
    }

    @media screen and (max-width: 1024px){
        height: 0.6vw;
    }

    @media screen and (max-width: 768px){
        height: 2vw;
    }
`;

export const Circle = styled.div`
    height: 14px;
    width: 14px;
    background-color: ${colors.turquoise};
    
    border: 2px solid #FFFFFF;
    border-radius: 50%;
    
    cursor: move; /* fallback */
    cursor: grab;
    
    z-index: 11000;
    position: absolute;
    
    transition: left 100ms, border-color 100ms ease-out;
    
    &.active {
        cursor: move; /* fallback */
        cursor: grabbing !important;
        
        transition: none;    
    }

    &:hover{
        border-color: ${colors.turquoise};
    }

    &:focus{
        border-color: ${colors.turquoise};
        outline-color: transparent!important;
    }
    
    @media screen and (max-width: 1024px){
        height: 1.25vw;
        width: 1.25vw
    }

    @media screen and (max-width: 768px){
        height: 4vw;
        width: 4vw;
    }

`;

export const CircleTooltip = styled.div`
    min-width: 350%;

    font-size: 0.9vw;
    font-weight: 600;
    text-align: center;
    position: absolute;
    top: 250%;
    left: 50%;
    transform: translateX(-50%);
    
    padding: 0.1em 0.5em;

    border: 1px solid #FFFFFF;
    border-radius: 4px;

    &.untouched{
        color: gray;
    }

&::after,
&::before {
    content: '';
    display: block;
    position: absolute;
    width: 0;
    height: 0;

    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
}

&::before {
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 3px solid transparent;
    border-bottom: 11px solid #FFFFFF;
}
&::after {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 5px solid transparent;
    border-bottom: 9px solid ${colors.background};
}

@media screen and (max-width: 1024px){
    font-size: 1.3vw;

}

@media screen and (max-width: 768px){
    font-size: 5vw;
}

`;

export const DefaultStateInfo = styled.div`
    position: absolute;
    white-space: nowrap;
    transform: translateX(calc(-50% + 7px));
    font-size: 0.7vw;
    // calculate based on font sizes of DefaultStateInfo and CircleTooltip
    top: calc(250% + 1.6vw);
    margin-top: 0.35vw;

    @media screen and (max-width: 1024px){
        font-size: 1vw;
        top: calc(250% + 2.3vw);
        margin-top: 0.5vw;
    }

    @media screen and (max-width: 768px){
        transform: translateX(calc(-50% + 15px));
        font-size: 4vw;
        top: calc(250% + 7vw);
        margin-top: 1vw;
    }
}
`;

export const DegreesTextContainer = styled.div`
    width: 125%;
    position: absolute;
    display: flex;
    justify-content: space-between;
    top: 50%;
    left: 0;
    cursor: text;
    
    transform: translate(0,5vw);
    
    @media screen and (max-width: 1024px){
        width: 125%;
        transform: translate(0,10vw);
    }

    @media screen and (max-width: 768px){
        width: 130%;       
        transform: translate(0,25vw);
    }

`;
export const Degree = styled.div`
    font-size: 0.75vw;
    position: relative;
    @media screen and (max-width: 1024px){
        font-size: 1.2vw;
    }

    @media screen and (max-width: 768px){
        font-size: 2.95vw;
    }
`;
