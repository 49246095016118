import * as React from "react";
import styled from "styled-components";
import { H4 } from "../__common__/styled-components";
import { StyledQuestionHeader } from "../StyledQuestionHeader";
import { connect } from "react-redux";
import { doSendSlide11Data } from "../../../actions";
import { doSetAllSlidersWereTouched } from '../../../actions/allSlidersWereTouched';
import { doSetSlideWarningShown } from '../../../actions/slideWarningShown';
import { allSlidersTouched, handleSliderTouch, generateSliderState } from "../__common__/RangeInput/sliderParentCallbacks";
import { findFirstFocusableElement } from '../__common__/firstFocusableHelper';
import RangeInput from "../__common__/RangeInput";
import BaseSlide from "../BaseSlide";
import {Config} from "../../../Config";

const ExRangeInput = styled(RangeInput)`
    border-radius: 0;
    & > div:first-child{
        border-radius: 0;
    }
    & .degrees-container{
        width: 150%;
        transform: translate(-17%, 6vw);
        & div{
            width: 35%;
            text-align: center;
        }
    }
    @media screen and (max-width: 768px){
        margin-bottom: 12vw;
        margin-top: 25vw;
        & .degrees-container{
            /* transform: translate(-10%,-15vw); */
            width: 140%;
            transform: translate(-14%, -25vw);
            & div{
                width: 30%;
                text-align: center;
            }
        }
    }

`;

class Slide11 extends React.Component {
    state = {
        percent: 0,
        showDefaultValuesWarning: false,
        slidersTouched: {
        },
        allSidersWereTouched: false,
        warningMessageShown: false
    }
    static getDerivedStateFromProps(nextProps) {
        return {
            percent: nextProps.futureProofing,
            allSidersWereTouched: nextProps.allSidersWereTouched.allSidersWereTouched,
            warningMessageShown: nextProps.warningMessageShown.warningMessageShown
        }
    }

    handleSliderTouch = handleSliderTouch.bind(this);
    allSlidersTouched = allSlidersTouched.bind(this, 11);
    generateSliderState = generateSliderState.bind(this);
    findFirstFocusableElement = findFirstFocusableElement;

    handleGoForward = () => {
        if (this.allSlidersTouched() || this.state.allSidersWereTouched || this.state.warningMessageShown) {
            this.props.history.push('/summary');
            return false;
        } else {
            this.setState({
                showDefaultValuesWarning: true,
                warningMessageShown: true
            });
            this.props.doSetSlideWarningShown(11, { warningMessageShown: true });
            return false;
        }
    }

    handleValueChange = (value) => {
        this.allSlidersTouched();
        this.props.doSendSlide11Data(value);
    }

    componentDidMount() {
        this.firstFocusableElement = this.findFirstFocusableElement();

        if (window.gtag) {
            window.gtag('config', Config.GOOGLE_ANALYTICS, {
                'page_title' : 'Slide 11',
                'page_path': `/assessment/11`
            });
        }
    }

    render() {
        const { percent } = this.state;
        const { header, description, optional_headline } = this.props.content;
        const slideData = {
            id: 10,
            title: header,
            text: description
        }
        const degrees = this.props.content.decisionMaker;

        return (
            <BaseSlide {...slideData} onGoForward={this.handleGoForward} hasToBeCenter={true} showDefaultValuesWarning={this.state.showDefaultValuesWarning} defaultValuesWarningMessage="The slider value is set to 0. If the value is correct click NEXT to continue the assessment." firstFocusableElement={this.firstFocusableElement}>
                <StyledQuestionHeader>
                    <H4 flexBasis="100%">{optional_headline}</H4>
                </StyledQuestionHeader>
                <div className="range-input-wrapper">
                    <ExRangeInput type="degree"
                                  value={percent}
                                  onValueChange={this.handleValueChange}
                                  degrees={degrees}
                                  handleSliderTouch={this.handleSliderTouch}
                                  name="servicesOverviewSlider"
                                  allSlidersWereTouched={this.state.allSidersWereTouched}
                                  slideWarningMessageShown={this.state.warningMessageShown}
                                  generateSliderState={this.generateSliderState} />
                </div>
            </BaseSlide>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        futureProofing: state.applySlidesData.futureProofing,
        slidesState: state.applySlidesData,
        allSidersWereTouched: state.applyAllSlidersWereTouched[10],
        warningMessageShown: state.applySlideWarningShown[10],
        content: {
            ...state.applyContent.steps[10],
            decisionMaker: [
                state.applyContent.umb_dictionary.slider_no_they_dont,
                state.applyContent.umb_dictionary.slider_partly,
                state.applyContent.umb_dictionary.slider_yes_complete_traceability,
            ]
        }
    }
}

const mapDispatchToProps = { doSendSlide11Data, doSetAllSlidersWereTouched, doSetSlideWarningShown };

export default connect(mapStateToProps, mapDispatchToProps)(Slide11);