import { ActionTypes } from "../actions/slideWarningShown";
const initialState = [
    {
        id: 1,
        warningMessageShown: false
    },
    {
        id: 2,
        warningMessageShown: false
    },
    {
        id: 3,
        warningMessageShown: false
    },
    {
        id: 4,
        warningMessageShown: false
    },
    {
        id: 5,
        warningMessageShown: false
    },
    {
        id: 6,
        warningMessageShown: false
    },
    {
        id: 7,
        warningMessageShown: false
    },
    {
        id: 8,
        warningMessageShown: false
    },
    {
        id: 9,
        warningMessageShown: false
    },
    {
        id: 10,
        warningMessageShown: false
    },
    {
        id: 11,
        warningMessageShown: false
    },
]

export const applySlideWarningShown = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SET_SLIDE_WARNING_SHOWN:
            return state.map(item => {
                if (item.id === action.payload.id) {
                    return { ...item, ...action.payload.warningMessageShown };
                }
                return item;
            })

        default:
            return state;
    }
}
