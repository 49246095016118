import * as React from "react";
import styled from "styled-components";
import QuestionHeader, { H4 } from "../__common__/styled-components";
import BaseSlide from "../BaseSlide";
import RadioContainer from "./RadioContainer";

import { connect } from "react-redux";
import { doSendSlide5Data } from "../../../actions";
import { doGoForward, doGoBackward } from "../../../actions";

import ScrollBarArea from "../__common__/ScrollBarArea";
// import Dropdown from "../__common__/Dropdown";
import uuid from "uuid-v4";
import StatementsNavigation from "../__common__/StatementsNavigation";
import { findFirstFocusableElement } from '../__common__/firstFocusableHelper';
import {Config} from "../../../Config";

const StyledScrollBarArea = styled(ScrollBarArea)`
    height: 48vh !important;
    max-height: 100%;
    
    font-size: 0.9vw;
    
    @media screen and (max-width: 1024px){
        font-size: 1.55vw;
        
    }
    @media screen and (max-width: 768px){
        display: none;
    }

`;
const ExH4 = styled(H4)`
    
    /* margin-top: 2vw; */
    margin: 2vw 0.8em 0 0.8em;
    @media screen and (max-width: 1024px){
    }
    
    @media screen and (max-width: 768px){

    }
`;
const StyledQuestionHeader = styled(QuestionHeader)`
    display: none;
    @media screen and (max-width: 768px){
        display: flex;
        & h4:first-child{
            padding: ${props => props.subSlideNumber || props.subSlideNumber === 0 ? props.subSlideNumber > 9 - 1 ? '0 10% 0 25%' : '0 10% 0 23%' : '0 20%'};
            &::before{
                left: ${props => props.subSlideNumber || props.subSlideNumber === 0 ? props.subSlideNumber > 9 - 1 ? '5%' : '5%' : '10%'};
            }
            &::after{
                left: ${props => props.subSlideNumber || props.subSlideNumber === 0 ? props.subSlideNumber > 9 - 1 ? '22%' : '19%' : '16.5%'};  
            }
        }
    }
`;
const MobileContainer = styled.div`
    display: none;
    @media screen and (max-width: 768px){
        display: flex;
        flex-direction: column;
        width: 90%;
        margin: 0 auto;
        margin-bottom: 18vw;
    }
`;
const StyledRadioContainer = styled(RadioContainer)`

    @media screen and (max-width: 1024px){
    }
    
    @media screen and (max-width: 768px){
        width: 100%;
        height: auto;
        flex-flow: row wrap;
        margin-top: 2rem;
        
        & > div{
            padding: 0 1rem;
            margin-bottom: 1rem;
            
            &:last-child{
                margin-bottom: 0;
            }
            
            & > div{
                margin-right: 5vw;
            }
        }
    }
`;


class Slide5 extends React.Component {
    state = {
        questions: [],
        isValidated: true,
        nextPressed: false,
        selectedQuestionIndex: 0
    }

    static getDerivedStateFromProps(nextProps) {

        if (nextProps.questions.length === 0) {
            return {
                questions: nextProps.content.culture_statements.map(item => {
                    return {
                        questionId: uuid(),
                        direct: item.direct,
                        positive: item.positive,
                        statement: item.statement,
                        selectedRadioId: null
                    }
                })
            }
        } else {
            return {
                questions: nextProps.questions.map(item => ({ ...item }))
            }
        }
    }

    findFirstFocusableElement = findFirstFocusableElement;

    handleSelect = (selectedRadioId, questionId) => {
        const { questions } = this.state;
        const newQuestions = questions.map(item => {
            if (item.questionId === questionId) {
                item.selectedRadioId = selectedRadioId;
            }
            return item;
        });
        this.props.doSendSlide5Data(newQuestions);
        const isAnyEmpty = questions.some(x => x.selectedRadioId == null);

        if (!isAnyEmpty) {
            this.setState({isValidated: true})
        }
    };

    handleGoForward = () => {
        const { questions } = this.state;
        const isAnyEmpty = questions.some(x => x.selectedRadioId == null);

        this.setState({
            nextPressed: true,
            isValidated: !isAnyEmpty
        });

        if (isAnyEmpty) {
            this.props.doSendSlide5Data(questions);
        }

        return !isAnyEmpty;
    };

    handleNextStatements = () => {
        const { selectedQuestionIndex, questions } = this.state;
        const amount = this.state.questions.length,
            selectedQuestionIsValid = !!questions[selectedQuestionIndex].selectedRadioId;

        window.scrollTo(0, 0);

        this.setState({
            nextPressed: true
        });

        if (!selectedQuestionIsValid) {
            return false;
        }

        // Last question
        if (selectedQuestionIndex === amount - 1) {
            this.handleGoForward();
            this.props.doGoForward();
            const { selectedMenuItemId } = this.props;
            const stepId = selectedMenuItemId + 1;
            this.props.history.push('/assessment/' + stepId + '/1');
            return;
        }

        this.setState(prevState => ({
            selectedQuestionIndex: prevState.selectedQuestionIndex + 1,
            nextPressed: false // reset before next question
        }));
    };

    handlePrevStatements = () => {
        const { selectedQuestionIndex } = this.state;

        window.scrollTo(0, 0);

        if (selectedQuestionIndex === 0) {
            const { selectedMenuItemId } = this.props;
            this.props.doGoBackward();
            this.props.history.push(`/assessment/${selectedMenuItemId - 1}`);
            return;
        }
        this.setState(prevState => ({ selectedQuestionIndex: prevState.selectedQuestionIndex - 1 }));
    }

    componentDidMount() {
        this.firstFocusableElement = this.findFirstFocusableElement();

        if (window.gtag) {
            window.gtag('config', Config.GOOGLE_ANALYTICS, {
                'page_title' : 'Slide 5',
                'page_path': `/assessment/5`
            });
        }
    }

    render() {
        const { questions, selectedQuestionIndex, nextPressed, isValidated } = this.state;
        const { header, description } = this.props.content;
        const slideData = {
            id: 5,
            title: header,
            text: description
        }

        return (
            <BaseSlide nextPressed={nextPressed} {...slideData} isValidated={!nextPressed || isValidated} validationErrorMessage="Please answer all questions." onGoForward={this.handleGoForward} hasToBeCenter={false} nextLink="6/1" firstFocusableElement={this.firstFocusableElement}>
                <StyledScrollBarArea>
                    {questions.map(item => <RadioContainerWithHeader key={item.questionId} questionId={item.questionId} text={item.statement} selectedRadioId={item.selectedRadioId} onSelect={this.handleSelect} />)}
                </StyledScrollBarArea>
                <StyledQuestionHeader subSlideNumber={selectedQuestionIndex}>
                    <H4 flexBasis="45%" style={{ textAlign: 'left' }}>
                        {questions[selectedQuestionIndex].statement}
                    </H4>
                </StyledQuestionHeader>
                <MobileContainer>
                    {/* <Dropdown value={selectedQuestionIndex} items={questions} onChange={this._mobile_handleSelectChange} /> */}
                    <StyledRadioContainer questionId={questions[selectedQuestionIndex].questionId}
                        selectedRadioId={questions[selectedQuestionIndex].selectedRadioId} onSelect={this.handleSelect} />
                    <StatementsNavigation
                        isValidated={!nextPressed || questions[selectedQuestionIndex].selectedRadioId !== null}
                        validationErrorMessage="Please choose an answer"
                        onGoForward={this.handleNextStatements}
                        onGoBackward={this.handlePrevStatements} />
                </MobileContainer>
            </BaseSlide>
        )
    }
}

const RadioContainerWithHeader = ({ questionId, text, selectedRadioId, onSelect }) => (
    <div style={{ padding: "0 1vw 7px", width: "96%", marginBottom: "1vw" }}>
        <ExH4 flexBasis="100%">{text}</ExH4>
        <RadioContainer questionId={questionId} selectedRadioId={selectedRadioId} onSelect={onSelect} />
    </div>
)

const mapStateToProps = (state) => {
    return {
        content: state.applyContent.steps && state.applyContent.steps[4],
        questions: state.applySlidesData.cultureQuestions,
        selectedMenuItemId: state.navmenu.selectedMenuItemId
    }
}

const mapDispatchToProps = { doSendSlide5Data, doGoForward, doGoBackward };

export default connect(mapStateToProps, mapDispatchToProps)(Slide5);
