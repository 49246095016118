import React from "react";
import styled from "styled-components";

import Logo from "./Logo.svg";


const Wrapper = styled.div`
    flex-basis: 100%;
    display: flex;
    align-items: center;
    height: 15%;

    @media screen and (max-width: 1024px){
        height: 10%;

    }

    @media screen and (max-width: 768px){
        display: none;
    }
`;

const H1 = styled.h1`
    margin-right: 55px;
`;

const H2 = styled.h2`
    text-transform: uppercase;
    font-weight: 100;
    font-size: 1.25vw;
    letter-spacing: 0.5pt;

    @media screen and (max-width: 1024px){
        font-size: 1.75vw;

    }

    @media screen and (max-width: 768px){
    }

`;

const Img = styled.img`
    width: 15vw;
    @media screen and (max-width: 1024px){
        width: 20vw;

    }

    @media screen and (max-width: 768px){
    }

`;

const LogoHeader = () => {
    return (
        <Wrapper>
            <H1>
                <Img src={Logo} alt="Nucleon" />
            </H1>
            <H2>Assessment</H2>
        </Wrapper>
    )
}
export default LogoHeader;

