import React from "react";
import styled from "styled-components";
import ExH4 from "./ExtendH4";

import RadioWithText from "../__common__/RadioWithText";

const Wrapper = styled.div`
    @media screen and (max-width: 1024px){
        margin-top: 2vw;
    }
    @media screen and (max-width: 768px){
        width: 100%;
        padding: 0 7% 0 0;
    }

`;

const RadioWrapper = styled.div`
    display: flex;
    padding: 0 35%;
    margin-top: 0.75vw;
    
    @media screen and (max-width: 1024px){
        margin-top: 1.5vw;

    }
    @media screen and (max-width: 768px){
        width: 100%;
        padding: 0 5%;
    }

`;

const ExRadioWithText = styled(RadioWithText)`
    & div{
        margin-bottom: 1vw;
    }

    @media screen and (max-width: 768px){
        & div{
            margin-right: 5vw;
        }
    }

`;

const QuestionWithRadio = ({ selectedRadioId, onSelect, text, isValidated }) => (
    <Wrapper>
        <ExH4>{text}</ExH4>
        <RadioWrapper>
            <ExRadioWithText className={!isValidated && !selectedRadioId ? "validation-error" : null} id={1} selectedRadioId={selectedRadioId} onSelect={onSelect} text="yes" />
            <ExRadioWithText className={!isValidated && !selectedRadioId ? "validation-error" : null} id={2} selectedRadioId={selectedRadioId} onSelect={onSelect} text="no" />
        </RadioWrapper>
    </Wrapper>
)

export default QuestionWithRadio;