import { ActionTypes } from "../actions/allSlidersWereTouched";
const initialState = [
    {
        id: 1,
        allSidersWereTouched: false
    },
    {
        id: 2,
        allSidersWereTouched: false
    },
    {
        id: 3,
        allSidersWereTouched: false
    },
    {
        id: 4,
        allSidersWereTouched: false
    },
    {
        id: 5,
        allSidersWereTouched: false
    },
    {
        id: 6,
        allSidersWereTouched: false
    },
    {
        id: 7,
        allSidersWereTouched: false
    },
    {
        id: 8,
        allSidersWereTouched: false
    },
    {
        id: 9,
        allSidersWereTouched: false
    },
    {
        id: 10,
        allSidersWereTouched: false
    },
    {
        id: 11,
        allSidersWereTouched: false
    },
]

export const applyAllSlidersWereTouched = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SET_ALL_SLIDERS_WERE_TOUCHED:
            return state.map(item => {
                if (item.id === action.payload.id) {
                    return { ...item, ...action.payload.allSidersWereTouched };
                }
                return item;
            })

        default:
            return state;
    }
}
