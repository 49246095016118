import React from "react";
import styled from "styled-components";

import ProgressBar from "../ProgressBar";


const StyledFooter = styled.footer`
    display: flex;
    align-items: center;
    flex-basis: 100%;
    height: 5%;
    @media screen and (max-width: 1024px){

    }
    @media screen and (max-width: 768px){
        display: none;
    }

`;

const Footer = () => {
    return (
        <StyledFooter>
            <ProgressBar />
        </StyledFooter>
    )
}
export default Footer;
