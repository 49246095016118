import * as React from "react";
import QuestionHeader, { H4 } from "../__common__/styled-components";
import BaseSlide from "../BaseSlide";

import { connect } from "react-redux";
import { doSendSlide6Data } from "../../../actions";

import { findFirstFocusableElement } from '../__common__/firstFocusableHelper';

import styled from "styled-components";
import uuid from "uuid-v4";
import Dropdown from "../__common__/Dropdown";
import RadioWithText from "../__common__/RadioWithText";
import {Config} from "../../../Config";

const RadioContainer = styled.div`
    margin-bottom: 3vw;
    width: 95%;
    @media screen and (max-width: 1024px){
    }
    @media screen and (max-width: 768px){
        margin-top: 10vw;
        margin-bottom: 20vw;
        width: 100%;
    }

`;

const ExRadioWithText = styled(RadioWithText)`
    flex-direction: row;
    margin-bottom: 1.25vw;
    padding: 0 4vw;
    & span{
        ${'' /* font-size: 0.8vw; */}
        text-align: left;
        text-transform: none;
        margin-left: 1.25vw;
    }
    @media screen and (max-width: 1024px){
        & span{
            font-size: 1.15vw;
        }
    }
    @media screen and (max-width: 768px){
        margin-bottom: 10vw;
        
        &:last-child{
            margin-bottom: 0;
        }
        & span {
            font-size: 3.5vw;
            margin-right: 0;
            margin-left: 5vw;
        }
    }

`;
const ExH4 = styled(H4)`
    padding: 0 4vw;
    /* font-size: 0.8vw; */

    margin-bottom: 1.25vw;
    margin-left: 0;
`;

const DesktopContainer = styled.div`
    height: 48vh !important;
    font-size: 0.9vw;
    margin-top: 1vw;
    width: 100%;

    @media screen and (max-width: 1024px){
        font-size: 1.55vw;
        
    }
    @media screen and (max-width: 768px){
        display: none;
    }
`;

const MobileContainer = styled.div`
    display: none;
    @media screen and (max-width: 768px){
        display: flex;
        flex-direction: column;
        width: 95%;
        margin: 0 auto;
        align-items: center;
    }
`;

// const StyledSpan = styled.span`
//     display: none;
//     @media screen and (max-width: 768px){
//         display: flex;
//         margin-top: 1vw;
//     }
//
// `
const StyledQuestionHeader = styled(QuestionHeader)`
    display: none;
    @media screen and (max-width: 768px){
        display: flex;
        & h4:first-child{
            padding: 0 10% 0 25%;
            &::before{
                left: 5%;
            }
            &::after{
                left: 22%;
            }
        }
    }
`;

class Slide6 extends React.Component {
    state = {
        method_statements: [],
        isValidated: true,
        input: true,
        currentStatementIndex: 0,
        nextPressed: false
    };

    static getCurrentIndexFromPath(pathname) {
        const urlParts = pathname.slice(1).split('/');
        return urlParts.length === 3 ? parseInt(pathname.slice(-1), 10) - 1 : 0;
    }

    static getDerivedStateFromProps(nextProps) {
        if (nextProps.method_statements.length === 0) {
            return {
                method_statements: nextProps.content.method_statements.map(item => ({
                    id: uuid(),
                    item: item,
                    answer: null
                }))
            }
        } else {
            return {
                method_statements: [...nextProps.method_statements],
            }
        }
    }

    findFirstFocusableElement = findFirstFocusableElement;

    constructor(props) {
        super(props);

        // set intial value for currentStatementIndex
        this.state.currentStatementIndex = Slide6.getCurrentIndexFromPath(props.location.pathname);
    }


    handleGoForward = () => {
        const { method_statements, currentStatementIndex } = this.state;

        this.setState({
            nextPressed: true
        });

        // validate current subpage
        if (method_statements[currentStatementIndex].answer === null) {
            this.setState({
                isValidated: false,
                input: false
            });
            return false;
        }

        // navigate through subpages
        if (currentStatementIndex < 2) {
            this.setState({
                nextPressed: false, // reset
                currentStatementIndex: currentStatementIndex + 1
            }, () => {
                let newCurrentStatementIndex = (this.state.currentStatementIndex + 1);

                if (window.gtag) {
                    window.gtag('config', Config.GOOGLE_ANALYTICS, {
                        'page_title' : 'Slide 6.' + newCurrentStatementIndex,
                        'page_path': `/assessment/6/${newCurrentStatementIndex}`
                    });
                }

                this.props.history.push('/assessment/6/' + (newCurrentStatementIndex));
            });
            return false;
        } else {
            this.props.doSendSlide6Data(method_statements);
            return true;
        }
    }

    handleGoBackward = () => {
        const { currentStatementIndex } = this.state;

        if (currentStatementIndex > 0) {
            this.setState({
                currentStatementIndex: currentStatementIndex - 1
            }, () => {
                if (window.gtag) {
                    window.gtag('config', Config.GOOGLE_ANALYTICS, {
                        'page_title' : 'Slide 6.' + currentStatementIndex,
                        'page_path': `/assessment/6/${currentStatementIndex}`
                    });
                }

                this.props.history.push('/assessment/6/' + (currentStatementIndex));
            });

            return false;
        } else {
            return true;
        }
    }

    handleSelect = (selectedRadioId, questionId) => {
        this.setState({
            isValidated: true
        });
        const { method_statements } = this.state;

        const newMethodStatements = method_statements.map(item => {
            if (item.id === questionId) {
                item.answer = selectedRadioId;
            }
            return item;
        });

        this.props.doSendSlide6Data(newMethodStatements);
    }

    handleSelectChange = (e) => {
        const currentStatementIndex = e.target.value;
        this.setState({
            currentStatementIndex: +currentStatementIndex
        });
    }
     // It's becaouse when coming from previous slide (7) component did update didn't fired
    componentDidMount() {
        this.firstFocusableElement = this.findFirstFocusableElement();

        if (window.gtag) {
            let currentIndex = this.state.currentStatementIndex + 1;
            window.gtag('config', Config.GOOGLE_ANALYTICS, {
                'page_title' : 'Slide 6.' + currentIndex,
                'page_path': `/assessment/6/${currentIndex}`
            });
        }
    }

    componentDidUpdate() {
        this.firstFocusableElement = this.findFirstFocusableElement();
    }

    render() {
        const { method_statements, isValidated, nextPressed, currentStatementIndex } = this.state;
        const { header, description } = this.props.content;
        const slideData = {
            id: 6,
            title: header,
            text: description
        };

        const selectedQuestion = method_statements[currentStatementIndex];

        return (
            <BaseSlide {...slideData} nextPressed={nextPressed} isValidated={!nextPressed || isValidated} validationErrorMessage="Please answer the question." onGoForward={this.handleGoForward} onGoBackward={this.handleGoBackward} hasToBeCenter={false} hasSubpages={3} firstFocusableElement={this.firstFocusableElement}>
                <DesktopContainer>
                    <RadioContainer className={!isValidated && !selectedQuestion.answer ? "validation-error" : null} selectedRadioId={selectedQuestion.answer}>
                        <ExH4>{selectedQuestion.item.statement}</ExH4>
                        <ExRadioWithText id={1} selectedRadioId={selectedQuestion.answer} onSelect={id => this.handleSelect(id, selectedQuestion.id)} text={selectedQuestion.item.answer1} />
                        <ExRadioWithText id={2} selectedRadioId={selectedQuestion.answer} onSelect={id => this.handleSelect(id, selectedQuestion.id)} text={selectedQuestion.item.answer2} />
                        <ExRadioWithText id={3} selectedRadioId={selectedQuestion.answer} onSelect={id => this.handleSelect(id, selectedQuestion.id)} text={selectedQuestion.item.answer3} />
                        <ExRadioWithText id={4} selectedRadioId={selectedQuestion.answer} onSelect={id => this.handleSelect(id, selectedQuestion.id)} text={selectedQuestion.item.answer4} />
                        <ExRadioWithText id={5} selectedRadioId={selectedQuestion.answer} onSelect={id => this.handleSelect(id, selectedQuestion.id)} text={selectedQuestion.item.answer5} />
                    </RadioContainer>
                </DesktopContainer>
                <StyledQuestionHeader subSlideNumber={currentStatementIndex}>
                    <H4 flexBasis="45%" style={{ textAlign: 'left' }}>{selectedQuestion.item.statement}</H4>
                </StyledQuestionHeader>
                <MobileContainer>
                    <Dropdown value={currentStatementIndex} items={[0, 1, 2]} onChange={this.handleSelectChange} />
                    <RadioContainer>
                        <ExRadioWithText id={1} selectedRadioId={selectedQuestion.answer} onSelect={id => this.handleSelect(id, selectedQuestion.id)} text={selectedQuestion.item.answer1} />
                        <ExRadioWithText id={2} selectedRadioId={selectedQuestion.answer} onSelect={id => this.handleSelect(id, selectedQuestion.id)} text={selectedQuestion.item.answer2} />
                        <ExRadioWithText id={3} selectedRadioId={selectedQuestion.answer} onSelect={id => this.handleSelect(id, selectedQuestion.id)} text={selectedQuestion.item.answer3} />
                        <ExRadioWithText id={4} selectedRadioId={selectedQuestion.answer} onSelect={id => this.handleSelect(id, selectedQuestion.id)} text={selectedQuestion.item.answer4} />
                        <ExRadioWithText id={5} selectedRadioId={selectedQuestion.answer} onSelect={id => this.handleSelect(id, selectedQuestion.id)} text={selectedQuestion.item.answer5} />
                    </RadioContainer>
                </MobileContainer>
            </BaseSlide>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        content: state.applyContent.steps[5],
        method_statements: state.applySlidesData.method_statements
    }
}

const mapDispatchToProps = { doSendSlide6Data };

export default connect(mapStateToProps, mapDispatchToProps)(Slide6);