import styled from 'styled-components';
import React from "react";
import { connect } from 'react-redux';

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    position: ${props => props.selectedMenuItemId === 2? 'relative': 'static'}
    @media screen and (max-width: 1024px){
    }
    @media screen and (max-width: 768px){
        min-height: 100%;
        flex-flow: row wrap;
        align-content: flex-start;
    }

`;

const QuestionHeader1 = styled.div`
    width: 100%;
    height: 10%;
    // transform: translateY(-50%);

    display: flex;
    align-items: center;
    position: relative;
    @media screen and (max-width: 1024px){
    }

    @media screen and (max-width: 768px){
        // transform: translateY(0%);
        justify-content: space-between;
        flex-direction: column;
        ${'' /* height: 25% */}
        height: auto;

        & > H4:first-child::before{
            content: '${props => props.slideNumber}';
            display: flex;
            height: 100%;
            position: absolute;
            left: 10%;
            font-size: 7vw;
            align-items: center;
            font-weight: 700;
        }
        
        & > H4:first-child::after{
            content: '';
            display: flex;
            height: 125%;
            position: absolute;
            width: 1px;
            left: 16.5%;
            background-color: #9BD5C4;
            font-size: 7vw;
            transform: translateY(50%);
            bottom: 50%;
        }

    }
`;
export const H4 = styled.h4`
    font-weight: 400;
    word-spacing: 1px;
    text-align: ${props => props.right ? 'right' : 'left'};
    flex-basis: ${props => props.flexBasis && props.flexBasis};
    letter-spacing: 0.3pt;
    margin: 0 0 0 0.8em;
    
    font-size: 0.9vw;
    position: relative;
    @media screen and (max-width: 1024px){
        font-size: 1.35vw;

    }
    @media screen and (max-width: 768px){
        font-size: 3.75vw;
        padding: 0 20%;
        margin: 0 0 2em 0;
        flex-basis: auto;
        width: 100%;
        text-align: left;
    }
`;

class QuestionHeader extends React.Component {
    render() {
        const { className, children, menuItemId, subSlideNumber } = this.props;
        let slideNumber = menuItemId;
        slideNumber += subSlideNumber || subSlideNumber === 0 ? `.${subSlideNumber + 1}` : '';
        return (
            <QuestionHeader1 className={className} slideNumber={slideNumber}>
                {children}
            </QuestionHeader1>
        )
    }
}
const mapStateToProps = (state) => ({
    menuItemId: state.navmenu.selectedMenuItemId
})
export default connect(mapStateToProps, {})(QuestionHeader);

