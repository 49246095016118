import styled from "styled-components";
import colors from "../__common__/colors";
export const NameWrapper = styled.span`
    display: none;
    
    @media screen and (max-width: 768px){
        width: 100%;
        display: flex;
        justify-content: center;
        font-size: 5vw;
        margin-top: 2vh;
        
        & span{
            border-bottom: 1px solid ${colors.turquoise};
            padding: 0 2vw;
        }
    }
`;