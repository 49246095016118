import * as React from "react";
import styled from "styled-components";
import { Scrollbars } from 'react-custom-scrollbars';
import './style.css';

const StyledScrollBars = styled(Scrollbars)`
    width: 100%;
    & > div:first-child{
        margin-right: -18px!important;
        margin-bottom: -18px!important;
        overflow-x: hidden !important;
        
        @media screen and (max-width: 1024px){
          padding-right: 18px !important;
          padding-bottom: 18px !important;
        }
    }
`;



const ScrollBarArea = (props) => {

    const renderTrackHorizontal = (props) => {
        return <div {...props} style={{display: 'none'}} className="track-horizontal"/>
    };

    const renderTrackVertical = (props) => {
        return <div {...props} className="track-vertical" />
    };

    return (
        <StyledScrollBars renderTrackVertical={renderTrackVertical} renderTrackHorizontal={renderTrackHorizontal} autoHeight={props.autoHeight} autoHeightMin={props.autoHeightMin} autoHeightMax={props.autoHeightMax} hideTracksWhenNotNeeded={true} className={props.className}
            // maxheight={this.props.maxheight}
            renderThumbVertical={props => <div {...props} className="thumb-vertical" />}>
            {props.children}
        </StyledScrollBars>
    )
}

export default ScrollBarArea;

