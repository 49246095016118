import styled from "styled-components";

export const Wrapper = styled.div `
    flex: 1 100%;
    padding: 0.6rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    &:focus{
        outline-color: transparent;
        outline: 0;
        *:first-child {
            background-color: rgba(154, 213, 195, 0.3);
            transform: scale(1.15);
        }
    }

    cursor: pointer;
    &:hover > *:first-child{
        background-color: #9BD5C4 !important;
        transform: scale(1.15);
    }

    @media screen and (max-width: 1024px){
        padding: 0;
    }

    @media screen and (max-width: 768px){
        &:hover > *:first-child{
            transform: none;
        }
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        text-align: left;
        padding: 0 2rem;
    }
`;

export const Text = styled.span `
    width: 100%;
    text-transform: uppercase;
    font-size: 0.75vw;
    text-align: center;

    @media screen and (max-width: 1024px){
        font-size: 1.3vw;
    }

    @media screen and (max-width: 768px){
        font-size: 5vw;
        font-weight: 400;
        
        flex: 1;
        text-align: left;
    }
`;