import * as React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Wrapper, Arrow } from "./style";

export const StyledNumberInput = styled.input.attrs({
    type: 'number',
    // pattern: '[0-9]*'
})`
    border: 0;
    background-color: transparent;
    text-align: center;
    color: white;
    outline: none;
    height: 2.5em;
    
    width: inherit;    
    letter-spacing: 0.3pt;
    font-weight: 600;
    ${props => !props.focused && 'box-shadow: none !important'}
    
    font-size: 0.9vw;
    
    @media screen and (max-width: 1024px){
        font-size: 1.5vw;
    }

    @media screen and (max-width: 768px){
        font-size: 5vw;
    }
`;

const ExArrow = styled(Arrow)`
    transition: 0.1s all ease-in-out;
    opacity: ${props => props.focused && '0 !important'};
    transform: ${props => props.focused && props.left && 'translate(50%, 20%)'};
    transform: ${props => props.focused && props.right && 'translate(-50%, 20%)'};
    
    ${props => props.minReached && 'opacity: 0.5'}
    ${props => props.maxReached && 'opacity: 0.5'}
`;

const ExWrapper = styled(Wrapper)`
    width: 50%;
    justify-content: center;
    
    @media screen and (max-width: 768px){
        height: 100%;
    }
`;

class NativeNumberInput extends React.Component {
    static propTypes = {
        value: PropTypes.any.isRequired,
        onDecrement: PropTypes.func.isRequired,
        onIncrement: PropTypes.func.isRequired,
        min: PropTypes.number,
        max: PropTypes.number,
        isValidated: PropTypes.bool
    };

    state = {
        value: '',
        hasChanged: false,
        focused: false,
        tempValue: 0
    };

    static getDerivedStateFromProps(nextProps) {
        return {
            value: nextProps.value,
            tempValue: nextProps.value,
            hasChanged: nextProps.hasChanged
        }
    }
    handleFocus = () => {

        setTimeout(() => { // For edge
            if (this.input) {
                this.input.select();
            }
        }, 1);

        this.setState(prevState => ({
            value: '',
            focused: true,
            tempValue: prevState.value
        }));
    };

    handleBlur = () => {
        const { tempValue } = this.state;
        this.setState({
            value: !tempValue ? 0 : tempValue,
            focused: false
        });
    };

    handleChange = (event) => {
        this.props.onChange(event);
    };

    handleIncrement = (event) => {
        this.props.onIncrement(event);
    };

    handleDecrement = (event) => {
        this.props.onDecrement(event);
    };

    handleKeyPress = (event) => {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);

        if (/[^0-9]/.test(keyValue)) { // input type=number has some issues in React, we are preventing all keys beside 0-9 and backspace + delete
            event.preventDefault();
        }

        if (keyValue === '0' && this.props.maxlength && (this.state.value + keyValue).length > this.props.maxlength) { // solves issue with entering 00000000... (and more) as value when maxlength is set to smaller value
            event.preventDefault();
        }
    };

    render() {
        const { placeholder, className, min, max, isValidated, maxlength } = this.props;
        const { value, hasChanged, focused } = this.state;

        return (
            <ExWrapper className={className}>
                <ExArrow right onClick={this.handleDecrement} focused={focused} minReached={(+value) <= min} />
                <StyledNumberInput type="number"
                                   className={!isValidated ? 'validation-error' : null}
                                   placeholder={placeholder ? placeholder : 'type here'}
                                   onKeyPress={this.handleKeyPress}
                                   onChange={this.handleChange}
                                   onFocus={this.handleFocus}
                                   onBlur={this.handleBlur}
                                   focused={focused}
                                   hasChanged={hasChanged}
                                   innerRef={ref => this.input = ref}
                                   value={value}
                                   maxLength={maxlength} />
                <ExArrow left onClick={this.handleIncrement} focused={focused} maxReached={(+value) >= max} />
            </ExWrapper>
        )
    }
}
export default NativeNumberInput;