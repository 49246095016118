export const ActionTypes = {
    SET_ALL_SLIDERS_WERE_TOUCHED: 'SET_ALL_SLIDERS_WERE_TOUCHED',
}

export const doSetAllSlidersWereTouched = (id, allSidersWereTouched) => ({
    type: ActionTypes.SET_ALL_SLIDERS_WERE_TOUCHED,
    payload: {
        id,
        allSidersWereTouched
    }
})
