import React from "react";
import PropTypes from "prop-types";
import RadioButton from "./RadioButton";
import { Wrapper, Text } from "./styles";

export default class RadioWithText extends React.Component {

    static propTypes = {
        id: PropTypes.number.isRequired,
        text: PropTypes.string,
        selectedRadioId: PropTypes.any,
        onSelect: PropTypes.func.isRequired,
        className: PropTypes.string
    }

    state = {
        isGoing: false
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { selectedRadioId, id } = nextProps;
        return {
            isGoing: selectedRadioId === id
        }
    }

    handleEnterPress = (event) => {
        if(event.key === "Enter" || event.keycode ===13){
            this.props.onSelect(this.props.id);
        }
    }

    handleClick = () => this.props.onSelect(this.props.id);

    render() {
        const { text, className } = this.props;
        const { isGoing } = this.state;
        return (
            <Wrapper onClick={this.handleClick} onKeyPress={this.handleEnterPress} tabIndex="0" className={className}>
                <RadioButton className="radio-with-text-radio" isGoing={isGoing} />
                <Text className="radio-with-text-label">{text || ''}</Text>
            </Wrapper>
        )
    }
}



