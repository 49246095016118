import styled from "styled-components";

export const Wrapper = styled.div`
    margin: 0 auto;
    width: 45%;
    height: 100%;
    position: relative;
    @media screen and (max-width: 1024px){
        width: 60%;
    }
    @media screen and (max-width: 768px){
        width: 90%;
    }

`;

export const StyledP = styled.p`
    font-size: 1vw;
    @media screen and (max-width: 1024px){
        font-size: 1.8vw;
    }
    @media screen and (max-width: 768px){
        font-size: 3.5vw;
    }

`;

export const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    /* position: absolute; */

    width: 100%;
`;

export const StyledButton = styled.button`
    border: 1px solid #FFFFFF;
    color: #FFFFFF;
    text-transform: uppercase;
    text-decoration: none;

    font-size: 0.95vw;
    margin: 1em auto 7em auto;
    padding: 0.45em 3.15em;

    transition: 0.15s transform ease-in-out;

    &:hover, &:focus{
        transform: scale(1.05);
    }

    background-color: transparent;
    outline: none;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 75%;

    @media screen and (max-width: 1024px){
        font-size: 1.6vw;
    }
    @media screen and (max-width: 768px){
        font-size: 4vw;
        width: 80%;
    }


`;

export const Clause = styled.p`
    font-size: 0.65vw;
    color: rgba(255,255,255,0.5);
    @media screen and (max-width: 1024px){
        font-size: 1.05vw;
    }
    @media screen and (max-width: 768px){
        font-size: 2.55vw;
    }

`;