import React from 'react';
import styled from 'styled-components';
import PageLayout from "./components/Layout";
import Hello from "./components/Slides/Hello";
import { Route } from "react-router-dom";
import Summary from './components/Pages/Summary';


import axios from "axios";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { doContentFetched } from "./actions";
import Spinner from "./components/Spinner";
import Finish from "./components/Pages/Finish";

const Wrapper = styled.div`
    width: 70vw;
    height: 98vh;
    margin: 0 auto;

    display: flex;
    align-items: center;
    @media screen and (max-width: 1024px){
        width: 95vw;
    }
    @media screen and (max-width: 768px){
        width: 100vw;
        height: 100vh;        
    }
`;


class App extends React.Component {
    state = {
        isFetching: false
    }
    componentDidMount() {
        this.setState({ isFetching: true });
        
        const idParam = window.location.pathname.substring(1);

        axios.get('/api/nucleon/', {
            params: {
              id: idParam
            }
          })
            .then(res => res.data)
            .then(res => {
                this.props.doContentFetched(res);
                this.setState({ isFetching: false });
                //WARNING: SOMETHING COULD GO WRONG HERE (reason: setState is async)
            });
    }
    render() {
        const { isFetching } = this.state;
        return (
            isFetching ? <Spinner /> :
                <Wrapper>
                    {/* <Switch> */}
                    <Route exact path="/" component={Hello} />
                    <Route exact path="/assessment/:id/:subpage?" render={({ location }) => <PageLayout location={location} />} />
                    <Route exact path="/summary" component={Summary} />
                    <Route exact path="/finish" component={Finish} />
                    {/* <Redirect to="/assessment/2" /> */}
                    {/* </Switch> */}
                </Wrapper>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        slidesData: state.applySlidesData,
        content: state.applyContent
    }
}

const mapDispatchToProps = { doContentFetched };

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));