import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const StyledDiv = styled.div`
    height: 1.2vw;
    min-height: 1.2vw;
    width: 1.2vw;
    min-width: 1.2vw;
    ${'' /* margin-bottom: 20%; */}
    background-color: ${props => props.checked ? '#9BD5C4' : null}!important;
    border: 1px solid #FFFFFF;
    border-radius: 50%;
    transition: 0.25s background-color ease-in-out, transform 0.15s ease-in-out;
    
    @media screen and (max-width: 1024px){
        height: 2.1vw;
        min-height: 2.1vw;
        width: 2.1vw;
        min-width: 2.1vw;
    }
    
    @media screen and (max-width: 768px){
        height: 7.5vw;
        min-height: 7.5vw;
        width: 7.5vw;
        min-width: 7.5vw;
        
        margin-bottom: 0;
    }
`;

const RadioButton = ({ isGoing, className }) => {
    return (
        <StyledDiv className={className} checked={isGoing} />
    )
}

RadioButton.propTypes = {
    isGoing: PropTypes.bool.isRequired
}

export default RadioButton;



