import React from "react";
import PropTypes from "prop-types";
import styled, { keyframes } from "styled-components";

const spin = keyframes`
  0%{
    transform: rotate(0deg);
  }
  100%{
    transform: rotate(360deg);
  }
`;

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
`;

const Spin = styled.div`
  /* border: 1px solid #a6a6a6; */
  border: 2px solid transparent;
  border-top-color: #fff;
  border-right-color: #fff;
  border-radius: 50%;

  height: 100px;
  width: 100px;
  margin: 0 auto;

  animation: ${spin} 0.9s infinite linear;
`;

const Text = styled.div`
  text-align: center;
  margin-top: 15px;
  font-weight: 400;
  font-size: 4.6vw;

  @media (min-width: 768px){
    font-size: 2.1vw;
  }

  @media (min-width: 1024px){
    font-size: 1.1vw;
  }
`;

export default class Spinner extends React.Component {
  static propTypes = {
    text: PropTypes.string
  };
  render() {
    const { text } = this.props;
    return (
      <Wrapper>
        <Container>
          <Spin></Spin>
          <Text>{text}</Text>
        </Container>
      </Wrapper>
    )
  }
}