import { H4 } from "../__common__/styled-components";
import styled from "styled-components";

const ExH4 = styled(H4)`
    font-size: 0.85vw;
    padding: 0 2em 0 3em;
    @media screen and (max-width: 1024px){

    }
    @media screen and (max-width: 768px){
        font-size: 3.25vw;
        padding: 0 !important;
    }

`;


export default ExH4;

