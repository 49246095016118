import React from "react";
import ReactDOM from 'react-dom';
import PropTypes from "prop-types";
import CircleWithTooltip from "./CircleWithTooltip";
import { Wrapper, Progress, Degree, DegreesTextContainer } from "./RangeInputStyles";
import styled from "../../../../../node_modules/styled-components";

export default class RangeInput extends React.Component {
    static propTypes = {
        onValueChange: PropTypes.func.isRequired,
        value: PropTypes.number.isRequired,
        degrees: PropTypes.array,
        type: PropTypes.string.isRequired, // => ['percent','degree']
        name: PropTypes.string.isRequired,
        handleSliderTouch: PropTypes.func,
        generateSliderState: PropTypes.func,
        allSlidersWereTouched: PropTypes.bool,
        slideWarningMessageShown: PropTypes.bool,
        forceMarkAsTouched: PropTypes.bool
    };

    static EXTENDED_MOVE_AREA = 100; // in pixels - additional space on both sides of slider to make easier set min and max value.

    state = {
        circleTranslateX: 0, // computed in ComponentDidMount
        isMouseDown: false,
        isKeyDown: false,
        isTouched: false, // if page was visited mark as touched
        value: 0,
        scaler: 1,
        rangeValues: []
    };

    handlers = {
        mouseUp: null,
        mouseDown: null,
        mouseMove: null,

        touchStart: null,
        touchEnd: null,
        touchMove: null,

        keyDown: null,
        keyUp: null,

        resizeWindow: null
    };

    static getDerivedStateFromProps(nextProps) {
        return {
            value: nextProps.value,
            scaler: nextProps.type === 'percent' ? 1 : 10
        }
    }

    constructor() {
        super();
        this.barRef = React.createRef();

        this.handlers.mouseUp = this.mouseUp.bind(this);
        this.handlers.mouseDown = this._handleMouseDown.bind(this);
        this.handlers.mouseMove = this._handleMouseMove.bind(this);

        this.handlers.touchStart = this._handleTouchStart.bind(this);
        this.handlers.touchEnd = this._handleTouchEnd.bind(this);
        this.handlers.touchMove = this._handleTouchMove.bind(this);

        this.handlers.keyDown = this._handleKeyDown.bind(this);
        this.handlers.keyUp = this._handleKeyUp.bind(this);

        this.handlers.resizeWindow = this._handleResizeWindow.bind(this);
    }

    componentDidMount() {
        if (typeof this.props.generateSliderState === 'function') {
            this.props.generateSliderState(this.props.name);
        }
        const circleTranslateX = this.calculateTranslateXFromPercent(this.state.value);
        const shouldMarkAsTouched = this.shouldMarkAsTouched();
        this.setState({
            circleTranslateX: circleTranslateX * this.state.scaler,
            isTouched: shouldMarkAsTouched
        });

        if (this.barRef.current) {
            this.calculateRangeValues();
        }

        window.addEventListener('resize', this.handlers.resizeWindow);
    }

    shouldMarkAsTouched = () => {
        return this.props.slideWarningMessageShown || this.props.allSlidersWereTouched || this.props.forceMarkAsTouched;
    }

    componentWillUnmount() {
        document.removeEventListener('mouseup', this.handlers.mouseUp);
        document.removeEventListener('mousedown', this.handlers.mouseDown);
        document.removeEventListener('mousemove', this.handlers.mouseMove);

        document.removeEventListener('touchstart', this.handlers.touchStart);
        document.removeEventListener('touchend', this.handlers.touchEnd);
        document.removeEventListener('touchmove', this.handlers.touchMove);

        document.removeEventListener('keydown', this.handlers.keyDown);
        document.removeEventListener('keyup', this.handlers.keyUp);

        window.removeEventListener('resize', this.handlers.resizeWindow);
    }

    calculateTranslateXFromPercent = (percent) => {
        const bar = this.getBarInfo();
        if (bar) {
            return (bar.width * percent) / 100;
        }
    };

    _handleResizeWindow = () => {
        this.calculateRangeValues();
        const circleTranslateX = this.calculateTranslateXFromPercent(this.state.value);
        this.setState({
            circleTranslateX: circleTranslateX * this.state.scaler
        });
    };

    _handleMouseDown = (e) => {
        e.preventDefault();

        document.addEventListener('mouseup', this.handlers.mouseUp);

        this.props.handleSliderTouch(this.props.name);
        this.setState({ isMouseDown: true, isTouched: true }, () => {
            document.addEventListener('mousemove', this.handlers.mouseMove);
        });
    };
    _handleTouchStart = (e) => {
        document.addEventListener('touchend', this.handlers.touchEnd);
        this.props.handleSliderTouch(this.props.name);
        this.setState({ isMouseDown: true, isTouched: true }, () => {
            document.addEventListener('touchmove', this.handlers.touchMove);
        });
    };

    unassignOnMouseMove = () => {
        document.removeEventListener('mousemove', this.handlers.touchmove);
    };

    unassignOnTouchMove = () => {
        document.removeEventListener('touchmove', this.handlers.touchMove);
    };

    alignSliderThumb = () => {
        if (this.props.type === 'degree') {
            let translateXValue = this.calculateTranslateXFromPercent(this.state.value);
            this.setState({ circleTranslateX: (translateXValue) * 10 })
        }
        else if (this.props.type === 'percent') {
            let translateXValue = this.calculateTranslateXFromPercent(this.state.value);
            this.setState({ circleTranslateX: (translateXValue) })
        }
    };

    _handleMouseUp = () => this.setState({ isMouseDown: false }, () => {
        this.unassignOnMouseMove();
        this.alignSliderThumb();
    });

    _handleTouchEnd = () => this.setState({ isMouseDown: false }, () => {
        this.unassignOnTouchMove();
        this.alignSliderThumb();
    });

    _handleMove = (clientX) => {
        const { isMouseDown } = this.state;
        if (!isMouseDown) return;

        const bar = this.getBarInfo();
        if (this.mouseOutOfRange(clientX, bar)) return;

        const { circleTranslateX, percent } = this.calculateTranslateXAndPercent(clientX);
        this.setState({ circleTranslateX: circleTranslateX, value: percent }, () => this.props.onValueChange(percent));
    };

    _handleTouchMove = (event) => {
        const { isMouseDown } = this.state;
        if (!isMouseDown) return;

        const bar = this.getBarInfo();
        if (this.mouseOutOfRange(event.changedTouches[0].clientX, bar)) return;

        this._handleMove(event.changedTouches[0].clientX);
    };

    _handleMouseMove = (event) => {
        const { isMouseDown } = this.state;
        if (!isMouseDown) return;

        const bar = this.getBarInfo();
        if (this.mouseOutOfRange(event.clientX, bar)) return;

        this._handleMove(event.clientX);
    };

    _handleKeyDown = (event) => {
        this.props.handleSliderTouch(this.props.name);
        if (this.state.isKeyDown === true) {
            
            this._handleKeyUp(event);
            return;
        };

        let increment = 1;
        let limits = {
            min: 0,
            max: this.props.type === 'degree' ? 10 : 100
        };
        let newVal;

        this.setState({
            isKeyDown: true,
            isTouched: true
        });

        if ((event.key === "ArrowLeft" || event.keycode ===37) && (this.state.value - increment) >= limits.min) {
            newVal = this.state.value - increment;
            this.props.onValueChange(newVal);
        }
        else if ((event.key === "ArrowRight" || event.keycode ===39)&& (this.state.value + increment) <= limits.max) {
            newVal = this.state.value + increment;
            this.props.onValueChange(newVal);
        }
        else { }
    };

    _handleKeyUp = (event) => {
        this.setState({
            isKeyDown: false
        });
        this.alignSliderThumb();
    };

    getBarInfo = () => {
        if (this.barRef.current) {
            const { left: minValue, width } = this.barRef.current.getBoundingClientRect();
            
            const maxValue = minValue + width;
            return {
                minValue,
                maxValue,
                width
            };
        }
    }

    mouseOutOfRange = (mousePositionX, bar) => (mousePositionX >= bar.maxValue + RangeInput.EXTENDED_MOVE_AREA
        || mousePositionX <= bar.minValue - RangeInput.EXTENDED_MOVE_AREA);

    mouseUp = () => {
        if (!this.state.isMouseDown) return;
        this.setState({ isMouseDown: false });
        this.unassignOnMouseMove();
        this.alignSliderThumb();
    };

    keyUp = () => {
        if (!this.state.isKeyDown) return;
        this.setState({ isKeyDown: false });
        this.alignSliderThumb();
    };

    _handleClick = (event) => {
        const { circleTranslateX, percent } = this.calculateTranslateXAndPercent(event.clientX);
        this.props.handleSliderTouch(this.props.name);
        this.setState({ isTouched: true });

        if (this.props.type === 'degree' && (percent > 10 || percent < 0)) return;
        if (percent > 100 || percent < 0) return;

        this.setState({
            circleTranslateX: circleTranslateX,
            value: percent
        }, () => this.props.onValueChange(percent));
    };

    calculateRangeValues = () => {
        const bar = this.getBarInfo();
        if (bar) {
            const numOfRanges = 10;
            let values = [];
            let i = 0;

            if (bar.width === 0) {
                return values;
            }

            const step = Number((bar.width / numOfRanges).toFixed(2));

            while (i <= bar.width) {
                values.push(Math.round(i));
                i += step;
            }

            this.setState({
                rangeValues: values
            });

            return values;
        }
    }

    calculateTranslateXAndPercent = (clientX) => {
        const bar = this.getBarInfo();
        let circleTranslateX = clientX - bar.minValue;
        let percent = ((circleTranslateX * 100) / bar.width) / this.state.scaler;
        const maxValue = 100 / this.state.scaler;

        if (percent > maxValue) {
            // we let move slider indicator little bit more than 100% to be easier set 100% value
            percent = maxValue;
        } else if (percent < 0) {
            percent = 0;
        }
        const value = Math.round(percent);

        if (circleTranslateX > bar.width) {
            circleTranslateX = bar.width;
        } else if (circleTranslateX < 0) {
            circleTranslateX = 0;
        }

        return {
            circleTranslateX,
            percent: value
        }
    };

    getProgress = () => {
        if (!this.barRef) return '0%';

        const { circleTranslateX } = this.state;
        const bar = this.getBarInfo();
        if (bar) {
            return (circleTranslateX * 100) / bar.width;
        }
    };

    render() {
        const { circleTranslateX, value, isMouseDown, isTouched } = this.state;
        const degrees = this.props.degrees || [];
        const className = this.props.className;
        const progress = this.getProgress();

        return (
            <Wrapper
                className={className}
                ref = {this.barRef}
            >

                <Progress
                    onMouseDown={this._handleClick}
                    onMouseUp={this.alignSliderThumb}
                    style={{ width: progress + '%' }}
                    className={isMouseDown ? 'active' : null} />
                <DegreesTextContainer
                    className="degrees-container">
                    {degrees.map((sentence, index) => <Degree key={index}><span>{sentence}</span></Degree>)}
                </DegreesTextContainer>
                <DegreesBarContainer
                    onMouseDown={this._handleClick}
                    onMouseUp={this.alignSliderThumb}
                    className="degrees-bar-container">
                    {degrees.map((degree, index) => <Bar key={index} />)}
                </DegreesBarContainer>
                <CircleWithTooltip
                    onMouseDown={this._handleMouseDown}
                    onTouchStart={this._handleTouchStart}
                    onKeyDown={this._handleKeyDown}
                    onKeyUp={this._handleKeyUp}
                    isMouseDown={isMouseDown}
                    translateX={circleTranslateX}
                    value={value}
                    type={this.props.type}
                    isTouched={isTouched} />
            </Wrapper>
        )
    }
}
const DegreesBarContainer = styled.div`
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    height: 0.4vw;
    @media screen and (max-width: 1024px){
        height: 2vw;
    }
`;
const Bar = styled.div`
    width: 1px;
    height: 0.95vw;
    background-color: rgba(255,255,255, 0.8);
    
    @media screen and (max-width: 1024px){
        height: 1.4vw;
    }

    @media screen and (max-width: 768px){
        height: 5vw;
    }
`;

