import { ActionTypes } from "../actions/reportData";
const initialState = {
    link: null,
    id: null
}

export const applyReportData = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SET_SLIDE_REPORT_LINK:
            return { ...state, ...action.payload };

        default:
            return state;
    }
}
