import Slide1 from "../Slides/Slide1";
import Slide2 from "../Slides/Slide2";
import Slide3 from "../Slides/Slide3";
import Slide4 from "../Slides/Slide4";
import Slide5 from "../Slides/Slide5";
import Slide6 from "../Slides/Slide6";
import Slide7 from "../Slides/Slide7";
import Slide8 from "../Slides/Slide8";
import Slide9 from "../Slides/Slide9";
import Slide10 from "../Slides/Slide10";
import Slide11 from "../Slides/Slide11";

const routes = [
    { id: 1, exact: true, path: '/assessment/1', component: Slide1, tooltip: 'Team' },
    { id: 2, exact: false, path: '/assessment/2', component: Slide2, tooltip: 'Competence' },
    { id: 3, exact: false, path: '/assessment/3', component: Slide3, tooltip: 'Proximity' },
    { id: 4, exact: false, path: '/assessment/4', component: Slide4, tooltip: 'Bureaucracy' },
    { id: 5, exact: false, path: '/assessment/5', component: Slide5, tooltip: 'Culture' },
    { id: 6, exact: false, path: '/assessment/6', component: Slide6, tooltip: 'Method' },
    { id: 7, exact: false, path: '/assessment/7', component: Slide7, tooltip: 'Method' },
    { id: 8, exact: false, path: '/assessment/8', component: Slide8, tooltip: 'Legacy' },
    { id: 9, exact: false, path: '/assessment/9', component: Slide9, tooltip: "Api's" },
    { id: 10, exact: false, path: '/assessment/10', component: Slide10, tooltip: 'Reusability' },
    { id: 11, exact: false, path: '/assessment/11', component: Slide11, tooltip: 'Future-proofing' },
]

export default routes;