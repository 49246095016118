import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import LogoHeader from "../../Slides/Hello/LogoHeader";

import { InputWithLabel, DropDownWithLabel } from "./inputs";

import ErrorMessage from "../../Slides/__common__/ErrorMessage";

import { Wrapper, StyledP, StyledForm, Clause } from "./styles";
import { findFirstFocusableElement } from '../../Slides/__common__/firstFocusableHelper';

import { StyledButton } from "./styles";
import axios from "axios";
import Spinner from "../../Spinner";
import { doSetReportData } from "../../../actions/reportData";
import { Config } from "../../../Config";

const ExLogoHeader = styled(LogoHeader)`
    margin-top: 1vw;
    @media screen and (max-width: 1024px){

    }
    @media screen and (max-width: 768px){
        margin-top: 10vw;
    }


`;

// const ExProgressBar = styled(ProgressBar2)`
//     position: absolute;
//     bottom: 0.75vw;
//     width: 215%;
//     left: 0;
//     transform: translateX(-25%);
// `;

const StyledInputWithLabel = styled(InputWithLabel)`
    width: 100%;
    input{
        width: auto;
    }

    input + span{
        left: 0;
        transform: translate(6%,50%);
    }

    @media screen and (max-width: 1024px){
        & > div > span:first-child{
            padding-left: 110%;
        }
    }
    @media screen and (max-width: 768px){
        input + span{
            transform: translate(0%,50%);
            top: 100%;
        }
        & > div{
            flex-flow: row;
            justify-content: flex-start;
            margin-bottom: 3vw;
        }
        & > div > span:first-child{
            padding-left: 0;
            order: 1;
        } 
    }

    &[for="accept"]{
        @media screen and (max-width: 768px){
            & > div{
                flex-flow: row;
                justify-content: flex-start;
                margin-bottom: 0;
            }
        }
    }

`;

// const StyledSpan = styled.span`
//     color: #C70039;
// `;
// eslint-disable-next-line
const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

class Summary extends React.Component {

    state = {
        email: '',
        emailValid: false,
        invalid: false,
        terms: false,
        termsValid: false,
        fetchingText: '',
        isFetching: false,
        isValidated: false,
        isServerError: false,
        selectedCompanySize: '',
        companySizes: [
            '1 - 19', '20 - 50', '51 - 100', '101 - 500', '501 - 1000', '> 1000'
        ],
        selectedIndustry: '',
        industries: [
            'Construction', 'Culture', 'E-commerce', 'Education', 'Energy', 'Finance', 'Health and welfare', 'IT', 'Life Science', 'Manufacturing', 'Public', 'Retail', 'Telecommunications', 'Transportation and logistics', 'Other'
        ],

        nucleons: 0,
        percent: 0,

        companyName: '',
        companyNameValid: false,
        teamName: '',
        teamNameValid: false,
        countries: ['Afghanistan', 'Albania', 'Algeria', 'Andorra', 'Angola', 'Anguilla', 'Antigua & Barbuda', 'Argentina',
            'Armenia', 'Australia', 'Austria', 'Azerbaijan', 'Bahamas', 'Bahrain', 'Bangladesh', 'Barbados', 'Belarus',
            'Belgium', 'Belize', 'Benin', 'Bermuda', 'Bhutan', 'Bolivia', 'Bosnia & Herzegovina', 'Botswana', 'Brazil',
            'Brunei Darussalam', 'Bulgaria', 'Burkina Faso', 'Burundi', 'Cambodia', 'Cameroon', 'Canada', 'Cape Verde',
            'Cayman Islands', 'Central African Republic', 'Chad', 'Chile', 'China', 'China - Hong Kong / Macau', 'Colombia',
            'Comoros', 'Congo', 'Congo, Democratic Republic of (DRC)', 'Costa Rica', 'Croatia', 'Cuba', 'Cyprus',
            'Czech Republic', 'Denmark', 'Djibouti', 'Dominica', 'Dominican Republic', 'Ecuador', 'Egypt', 'El Salvador',
            'Equatorial Guinea', 'Eritrea', 'Estonia', 'Ethiopia', 'Fiji', 'Finland', 'France', 'French Guiana', 'Gabon',
            'Gambia', 'Georgia', 'Germany', 'Ghana', 'Great Britain', 'Greece', 'Grenada', 'Guadeloupe', 'Guatemala', 'Guinea',
            'Guinea-Bissau', 'Guyana', 'Haiti', 'Honduras', 'Hungary', 'Iceland', 'India', 'Indonesia', 'Iran', 'Iraq',
            'Israel', 'Italy', 'Ivory Coast', 'Jamaica', 'Japan', 'Jordan', 'Kazakhstan', 'Kenya', 'Kosovo', 'Kuwait', 'Kyrgyzstan',
            'Laos', 'Latvia', 'Lebanon', 'Lesotho', 'Liberia', 'Libya', 'Liechtenstein', 'Lithuania', 'Luxembourg', 'Macedonia',
            'Madagascar', 'Malawi', 'Malaysia', 'Maldives', 'Mali', 'Malta', 'Martinique', 'Mauritania',
            'Mauritius', 'Mayotte', 'Mexico', 'Moldova, Republic of', 'Monaco', 'Mongolia', 'Montenegro', 'Montserrat',
            'Morocco', 'Mozambique', 'Myanmar/Burma', 'Namibia', 'Nepal', 'New Zealand', 'Nicaragua', 'Niger', 'Nigeria',
            'North Korea', 'Norway', 'Oman', 'Pacific Islands', 'Pakistan', 'Panama', 'Papua New Guinea', 'Paraguay', 'Peru', 'Philippines',
            'Poland', 'Portugal', 'Puerto Rico', 'Qatar', 'Reunion', 'Romania', 'Russian Federation', 'Rwanda',
            'Saint Kitts and Nevis', 'Saint Lucia', 'Saint Vincent and the Grenadines', 'Samoa', 'Sao Tome and Principe',
            'Saudi Arabia', 'Senegal', 'Serbia', 'Seychelles', 'Sierra Leone', 'Singapore', 'Slovak Republic (Slovakia)',
            'Slovenia', 'Solomon Islands', 'Somalia', 'South Africa', 'South Korea', 'South Sudan', 'Spain', 'Sri Lanka', 'Sudan', 'Suriname',
            'Swaziland', 'Sweden', 'Switzerland', 'Syria', 'Tajikistan', 'Tanzania', 'Thailand', 'Netherlands', 'Timor Leste',
            'Togo', 'Trinidad & Tobago', 'Tunisia', 'Turkey', 'Turkmenistan', 'Turks & Caicos Islands', 'Uganda', 'Ukraine',
            'United Arab Emirates', 'United States of America (USA)', 'Uruguay', 'Uzbekistan', 'Venezuela', 'Vietnam',
            'Virgin Islands (UK)', 'Virgin Islands (US)', 'Yemen', 'Zambia', 'Zimbabwe'],
        selectedCountry: '',

        reportDownloadLink: null,
        reportId: null,
        shouldShowErrorMessage: false,
        doNotSendEmail: false,
    }

    findFirstFocusableElement = findFirstFocusableElement;

    validateItemsStrings = [
        'email',
        'terms',
        'companyName',
        'teamName'
    ];

    validateAll() {
        let states = this.state;
        for (let i = 0; i < this.validateItemsStrings.length; i++) {
            for (var prop in states) {
                if (prop === this.validateItemsStrings[i]) {
                    if (!states[prop + 'Valid']) {
                        this.setState({ isValidated: false });
                        return false;
                    }
                    else {
                        continue;
                    }
                }
            }
        }

        this.setState({ isValidated: true });
        return true;
    }

    handleSubmitKeyDown = (event) => {
        if (event.key === "Tab" || event.keycode === 9) {
            event.preventDefault();
            event.stopPropagation();
            this.firstFocusableElement.focus();
        }
    }



    componentDidMount() {
        this.setState({ fetchingText: 'Assessing your inputs...', isFetching: true });
        this.firstFocusableElement = this.findFirstFocusableElement();

        if (window.gtag) {
            window.gtag('config', Config.GOOGLE_ANALYTICS, {
                'page_title': 'Summary',
                'page_path': `/summary`
            });
        }

        axios.post('/api/nucleon/SendSurveyData', this.props.slidesState)
            .then(res => {

                let selectedCompanySize = this.props.preppedSurveyInput.companySize ? this.props.preppedSurveyInput.companySize : this.state.companySizes[0];
                let selectedIndustry = this.props.preppedSurveyInput.industry ? this.props.preppedSurveyInput.industry : this.state.industries[0];
                let selectedCountry = this.props.preppedSurveyInput.country ? this.props.preppedSurveyInput.country : this.state.countries[0];
                let teamName = this.props.preppedSurveyInput.teamName ? this.props.preppedSurveyInput.teamName : undefined;
                let companyName = this.props.preppedSurveyInput.companyName ? this.props.preppedSurveyInput.companyName : undefined;
                let email = this.props.preppedSurveyInput.email ? this.props.preppedSurveyInput.email : undefined;

                let teamNameValid = this.props.preppedSurveyInput.teamName ? true : false;
                let companyNameValid = this.props.preppedSurveyInput.companyName ? true : false;
                let emailValid = this.props.preppedSurveyInput.email ? true : false;
                let doNotSendEmail = this.props.preppedSurveyInput.doNotSendEmail ? true : false;

                this.setState(prev => ({
                    isFetching: false,
                    selectedCompanySize: selectedCompanySize,
                    selectedIndustry: selectedIndustry,
                    nucleons: res.data.nucleonNumber,
                    percent: res.data.betterThenNumber,
                    teamName: teamName,
                    companyName: companyName,
                    selectedCountry: selectedCountry,
                    email: email,
                    teamNameValid: teamNameValid,
                    companyNameValid: companyNameValid,
                    emailValid: emailValid,
                    doNotSendEmail: doNotSendEmail
                })), () => {
                    this.setState({
                        isFetching: false,
                        isServerError: true
                    });
                }

            });
    }

    handleSubmit = (e) => {
        e.preventDefault();

        if (this.validateAll()) {
            this.setState({ fetchingText: 'Please wait while we build your report...', isFetching: true, isValidated: true });

            const idParam = window.location.pathname.substring(1);

            const newData = {
                ...this.props.slidesState,
                email: this.state.email,
                selectedCompanySize: this.state.selectedCompanySize,
                selectedIndustry: this.state.selectedIndustry,
                companyName: this.state.companyName.trim(),
                teamName: this.state.teamName.trim(),
                country: this.state.selectedCountry.trim() || this.state.countries[0],
                id: idParam
            };
            axios.post('/api/nucleon/sendsurveyfinaldata', newData)
                .then(res => {
                    this.props.doSetReportData(res.headers.location, res.data.id);
                    this.props.history.push('/finish');
                }, () => {
                    this.setState({
                        isFetching: false,
                        isServerError: true
                    });
                });
        } else {
            this.setState({ shouldShowErrorMessage: true });
        }
    }

    handleEmailChange = (event) => {
        const value = event.target.value;
        if (value && emailRegex.test(value)) {
            this.setState({
                email: value.trim(),
                emailValid: true
            }, this.validateAll);
        } else {
            this.setState({
                email: value.trim(),
                emailValid: false
            }, this.validateAll)
        }
    }

    handleIndustryChange = (event) => {
        const { value } = event.target;
        this.setState({ selectedIndustry: value });
    }

    handleCompanySizeChange = (event) => {
        const { value } = event.target;
        this.setState({ selectedCompanySize: value });
    }

    handleTermsChange = (event) => {
        const { checked } = event.target;
        if (checked) {
            this.setState({
                terms: checked,
                termsValid: true
            }, this.validateAll);
        } else {
            this.setState({
                terms: checked,
                termsValid: false
            }, this.validateAll);
        }
    }

    handleCompanyNameChange = (event) => {
        const { value } = event.target;
        if (value) {
            this.setState({
                companyName: value,
                companyNameValid: true
            }, this.validateAll);
        } else {
            this.setState({
                companyName: value,
                companyNameValid: false
            }, this.validateAll);
        }
    }

    handleTeamNameChange = (event) => {
        const { value } = event.target;
        if (value) {
            this.setState({
                teamName: value,
                teamNameValid: true
            }, this.validateAll);
        } else {
            this.setState({
                teamName: value,
                teamNameValid: false
            }, this.validateAll)
        }
    }

    handleCountryChange = (event) => {
        const { value } = event.target;
        this.setState({ selectedCountry: value });
    }

    render() {
        const { isValidated, isServerError, shouldShowErrorMessage, fetchingText, isFetching, companySizes, countries, selectedCountry, industries, selectedCompanySize, selectedIndustry, nucleons, percent } = this.state;
        let error;
        if (!isValidated && shouldShowErrorMessage) {
            error = <ErrorMessage message="Please fill out all fields with valid data."></ErrorMessage>
        }
        return (
            !isFetching && isServerError ? <ErrorMessage message="There was a problem while parsing your inputs, please try again later"></ErrorMessage> :
                isFetching ? <Spinner text={fetchingText} /> :
                    <Wrapper>
                        <ExLogoHeader />
                        <StyledP>Thank you for taking the NUCLEON<span>&trade;</span> assessment!</StyledP>
                        <StyledP>Your Nucleon<span>&trade;</span> number, calculated on the basis on your input, is {nucleons} - which indicates that you are performing better than {percent} of comparable teams.</StyledP>
                        <StyledP>Driving high-performance teams is all about continuous focus and improvement, and if you want to have a report showing the factors leading to your result, a "laundry list" of your most important focus points and a prioritized heatmap, fill out the form below, and receive it instantly in your inbox.</StyledP>

                        <StyledForm onSubmit={this.handleSubmit} className={shouldShowErrorMessage ? 'form-invalid' : null}>
                            <InputWithLabel className={!this.state.emailValid ? "validation-error" : null} type="text" text="E-mail" htmlFor="email" placeholder="type in your email" onChange={this.handleEmailChange} invalid={this.state.invalid} value={this.state.email || ''} maxlength="200" />

                            <InputWithLabel className={!this.state.companyNameValid ? "validation-error" : null} type="text" text="Company Name" htmlFor="companyName" placeholder="type in your company name" onChange={this.handleCompanyNameChange} value={this.state.companyName || ''} maxlength="100" />

                            <InputWithLabel className={!this.state.teamNameValid ? "validation-error" : null} type="text" text="Team Name" htmlFor="teamName" placeholder="type in your team name" onChange={this.handleTeamNameChange} value={this.state.teamName || ''} maxlength="50" />
                            <DropDownWithLabel text="Country" items={countries} value={selectedCountry} onChange={this.handleCountryChange} />

                            <DropDownWithLabel text="Industry" items={industries} value={selectedIndustry} onChange={this.handleIndustryChange} />

                            <DropDownWithLabel text="Company size" items={companySizes} value={selectedCompanySize} onChange={this.handleCompanySizeChange} />
                            <StyledInputWithLabel className={!this.state.termsValid ? "validation-error" : null} type="checkbox" text="I accept to the Terms and Conditions" htmlFor="accept" onChange={this.handleTermsChange}>
                                <Clause>Your information is safe with us. We use collected information to make available and to improve our high standard of reliable service. We use such information to: respond to inquiries or service requests and monitor such responses; provide information about and market our products or services; resolve problems; and manage the Site, the Applications and assess their usage and usage of the products and services.</Clause>
                                <Clause>Personally identifiable information gathered during your interactions with the Application, will not be stored, but may be aggregated with similar data from other users and may be used to benchmark and analyze user trends and to generate aggregate statistical data concerning performance, and other similar subjects. Please read our Privacy Policy <a href="https://www.7n.com/privacy-policy/" target="_blank" rel="noopener noreferrer">here</a> for details.</Clause>
                            </StyledInputWithLabel>
                            {error}
                            {this.renderButtonText()}
                        </StyledForm>
                    </Wrapper>
        )
    }

    renderButtonText() {
        const { doNotSendEmail } = this.state;
        return (
            doNotSendEmail ?
                <StyledButton onKeyDown={this.handleSubmitKeyDown}>Submit assessment report now</StyledButton> :
                <StyledButton onKeyDown={this.handleSubmitKeyDown}>Get my assessment report now</StyledButton>
        )
    }
    
}

const mapStateToProps = (state) => ({
    slidesState: state.applySlidesData,
    reportData: state.applyReportData,
    preppedSurveyInput: state.applyContent.preppedSurveyInput
});

const mapDispatchToProps = { doSetReportData };

export default connect(mapStateToProps, mapDispatchToProps)(Summary);