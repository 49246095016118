import React from "react";
import styled from "styled-components";
import colors from "./__common__/colors";
import Close from "../resources/close.svg";
import ScrollBarArea from "./__common__/ScrollBarArea";

const StyledScrollBarArea = styled(ScrollBarArea)`
   width: 100%;
   height: 100%;
   top: 1.5vh;
`;

const Ul = styled.ul`
    list-style-type: none;
    padding: 0 22px 2em 0;
    display: flex;
    flex-direction: column-reverse;
    margin: 0;
    @media screen and (max-width: 1024px){

    }
    @media screen and (max-width: 768px){
        padding: 0 5vw;
    }
`;

const Li = styled.li`
    font-size: 0.8vw;
    text-align: left;
    margin-bottom: 1.75em;
    &:first-child{
        margin-bottom: 0 !important;
    }
    @media screen and (max-width: 1024px){
        font-size: 1.3vw;
        margin-bottom: 1.35vw;
    }
    @media screen and (max-width: 768px){
        font-size: 3.5vw;
        margin-top: 4.5vw;
        padding: 0 10vw;
    }

`;

const Span = styled.span`
    color: ${colors.turquoise};
`;

const Img = styled.img`
    position: absolute;
    top: 0;
    right: -3px;
    transform: translate(-50%, 50%);
    cursor: pointer;
    z-index: 10;
    display: block;

    @media screen and (max-width: 1024px){
        display: block;
    }

    // Ipads pro styles
    @media screen and (min-width: 769px) and (max-width: 1024px){
        top: -7px;
        right: -6px;
    }

`;
const Wrapper = styled.div`
    opacity: ${props => props.visible ? '1' : '0'};
    visibility: ${props => props.visible ? 'visible' : 'hidden'};
    transition: 0.1s all ease-in-out;
    background-color: ${colors.background};
    z-index: 12000;

    position: absolute;
    width: 50vw;
    height: 36vh;
    left: 35vw;
    top: 25vh;
    border: 1px solid white;
    border-radius: 7px;
    box-shadow: 0 0 16px 0 rgba(0,0,0,0.25);
    padding: 2vw!important;
    max-height: 385px;

    @media screen and (max-width: 1024px){
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform: none;
        border: none;
        margin-left: 0!important;
    }

    // Ipads pro styles
    @media screen and (min-width: 769px) and (max-width: 1024px){
        width: 50vw;
        height: 36vh;
        left: 35vw;
        top: 25vh;
        border: 1px solid white;
        border-radius: 7px;
        box-shadow: 0 0 16px 0 rgba(0,0,0,0.25);
        padding: 2vw!important;
        max-height: 385px;
    }
`;


const InfoModal = ({ items, visible, onClose }) => (
    <Wrapper visible={visible}>
        <Img src={Close} alt="Close" onClick={onClose} />
        <StyledScrollBarArea>
            <Ul>
                {items.map((item, index) => <Li key={'info-item' + (index + 1).toString()}><Span>{index + 1} - </Span>{item}</Li>)}
            </Ul>
        </StyledScrollBarArea>
    </Wrapper>
)


export default InfoModal;