export const ActionTypes = {
    SET_SLIDE_REPORT_LINK: 'SET_SLIDE_REPORT_LINK',
}

export const doSetReportData = (link, id) => ({
    type: ActionTypes.SET_SLIDE_REPORT_LINK,
    payload: {
        link, id
    }
})
