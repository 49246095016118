import { combineReducers } from 'redux';
import { navmenu } from './navmenu';
import { applySlidesData } from './applySlidesData';
import { applyContent } from './applyContent';
import { applyAllSlidersWereTouched } from "./applyAllSlidersWereTouched";
import { applySlideWarningShown } from "./applySlideWarningShown";
import { applyReportData } from "./applyReportData";

export default combineReducers({
    navmenu,
    applySlidesData,
    applyContent,
    applyAllSlidersWereTouched,
    applySlideWarningShown,
    applyReportData
});