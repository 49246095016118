import { slideActionTypes as ActionTypes } from "../actions"
import TeamMember from "../components/Slides/__common__/models/TeamMember";
const initialState = {
    teamMembers: [],
    proximity: 0,
    bureaucracy: 0,
    cultureQuestions: [],
    method_statements: [],

    legacyPercent: 0, // DELETE
    legacy: {
        historicReasons: 0,
        effort: 0,
        third: null
    },

    apiPercent: 0,
    reusability: 0,
    futureProofing: 0
}

export const applySlidesData = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SEND_SLIDE_1_DATA:
            return { ...state, teamMembers: [...action.teamMembers] };

        case ActionTypes.SEND_SLIDE_2_DATA:
            return { ...state, teamMembers: [...action.teamMembers] };

        case ActionTypes.SEND_SLIDE_3_DATA:
            return { ...state, proximity: action.proximity };

        case ActionTypes.SEND_SLIDE_4_DATA:
            return { ...state, bureaucracy: action.bureaucracy };

        case ActionTypes.SEND_SLIDE_5_DATA:
            return { ...state, cultureQuestions: action.questions };

        case ActionTypes.SEND_SLIDE_6_DATA:
            return { ...state, method_statements: action.method_statements };

        case ActionTypes.SEND_SLIDE_7_DATA:
            return { ...state, teamMembers: [...action.teamMembers] };

        case ActionTypes.SEND_SLIDE_8_DATA:
            return { ...state, legacy: { ...action.legacy } };

        case ActionTypes.SEND_SLIDE_9_DATA:
            return { ...state, apiPercent: action.apiPercent };

        case ActionTypes.SEND_SLIDE_10_DATA:
            return { ...state, reusability: action.reusability };

        case ActionTypes.SEND_SLIDE_11_DATA:
            return { ...state, futureProofing: action.futureProofing };
        
        case ActionTypes.CONTENT_FETCHED:
            return { ...state, teamMembers: [...action.content.preppedSurveyInput.teamMembers] };

        default:
            return state;
    }
}
