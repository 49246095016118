import React from "react";
import styled from "styled-components";
import ExH4 from "./ExtendH4";

import RangeInput from "../__common__/RangeInput";

const ExRangeInput = styled(RangeInput)`
    margin: 2vw auto 0 auto;
`;

const Wrapper = styled.div`
    @media screen and (max-width: 1024px){
        margin-top: 4vw;

    }
    @media screen and (max-width: 768px){
        width: 100%;
        ${ExRangeInput}{
            padding: 0 !important;
        }
    }

`;

const QuestionWithInput = ({ percent, text, onValueChange, handleSliderTouch, generateSliderState, name, allSlidersWereTouched, slideWarningMessageShown }) => (
    <Wrapper>
        <ExH4>{text}</ExH4>
        <div className="range-input-wrapper">
            <ExRangeInput type="percent" value={percent} onValueChange={onValueChange} handleSliderTouch={handleSliderTouch} name={name} allSlidersWereTouched={allSlidersWereTouched} slideWarningMessageShown={slideWarningMessageShown} generateSliderState={generateSliderState}/>
        </div>
    </Wrapper>
)

export default QuestionWithInput;