import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const StyledNavLink = styled(NavLink)`
    width: 9px;
    height: 9px;
    background-color: #92CAB1;
    border: 1px solid rgba(255,255,255,0.55) !important;
    border-radius: 100%;
    display: flex;
    align-items: center;
    text-decoration: none;
    cursor: ${props => props.ok === "true" ? 'cursor' : 'auto'};
    transition: 0.15s transform ease-in-out;
    box-sizing: border-box;
    &.active{
        transform: scale(1.5)  !important;
    }

    &:hover{
        transform: ${props => props.ok === "true" ? ' scale(1.3)' : ' scale(1)'};
    }

    @media screen and (max-width: 1024px){
        width: 7px;
        height: 7px;

    }
    @media screen and (max-width: 768px){
    }
`;

export const ListItem = styled.li`
    position: relative;
    margin-bottom: 20px;
    
    &:last-child{
        margin-bottom: 0;
    }

    @media screen and (max-width: 1024px){
        margin-bottom: 18px;
    }
    @media screen and (max-width: 768px){
    }
`;

export const Tooltip = styled.div`
    position: absolute;
    white-space: normal;
    text-transform: uppercase;
    font-size: 1.05vw;
    line-height: 1.4vw;
    visibility: ${props => props.isVisible ? 'visible' : 'hidden'};
    opacity: ${props => props.isVisible ? 1 : 0};
    right: ${props => props.isVisible ? '250%' : '150%'};
    transition: 0.25s all ease-in-out;
    max-width: 150px;
    text-align: right;
    margin-top: calc(-0.675vw + 6px);

    @media screen and (max-width: 1024px){
        font-size: 1.4vw;
        line-height: 1.8vw;
        margin-top: calc(-0.9vw + 5px);

    }
    @media screen and (max-width: 768px){

    }
`;