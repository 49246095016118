import * as React from "react";
import styled from "styled-components";

import { connect } from "react-redux";
import { doSendSlide7Data } from "../../../actions";
import { doSetAllSlidersWereTouched } from '../../../actions/allSlidersWereTouched';
import { doSetSlideWarningShown } from '../../../actions/slideWarningShown';

import QuestionHeader, { H4 } from "../__common__/styled-components";
import BaseSlide from "../BaseSlide";
import { doGoForward, doGoBackward } from "../../../actions";

import { Table, TableHeader, Cell } from "../Slide2/styles";

import ScrollBarArea from "../__common__/ScrollBarArea";

import StatementsNavigation from "../__common__/StatementsNavigation";
import { NameWrapper } from "../__common__/NameWrapper";
import NativeNumberInput from "../__common__/NativeNumberInput/NativeNumberInput";
import { findFirstFocusableElement } from '../__common__/firstFocusableHelper';
import {Config} from "../../../Config";

const MIN_MONTH = 1;
const MAX_MONTH = 999;

const StyledWrapper = styled.div`{
    padding-bottom: 2em;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    
`;

const ExtendedTable = styled(Table)`
    ${'' /* padding-top: 2vw; */}
    width: 100%;
    display:flex;
    flex-wrap: wrap;
    &::after{
        ${'' /* transform: translateX(-15%) !important; */}
        position: absolute;
        width: 100%;
        bottom: 4vw;
        left: 10%;
    }
`;

const ExtendedTableHeader = styled(TableHeader)`
    padding-right: 0;
    & > div{
        width: 45%;
        padding: 0;
        margin-right: 5%;
    }

    @media screen and (max-width: 1024px) {
        padding-right: 3.5%;
    }

`;

const HeaderColumn = styled(Cell)`
    display: flex;
    margin-bottom: 0.4vw;
    width: 50%;
    & > div{
        flex: 1;
        height: 100%;
    }


`;


const HeaderCell = styled(Cell)`
    font-size: 1vw;
    @media screen and (max-width: 1024px){
        font-size: 1.7vw;
    }
    @media screen and (max-width: 768px){
    }

`;

const Row = styled.div`
    display: flex;
    width: 45%;
    margin-right: 5%;
    & > div{
        flex: 1;
    }
    @media screen and (max-width: 1024px){

    }
    @media screen and (max-width: 768px){
    }
`;

const RowCell = styled(Cell)`
    ${'' /* border: 1px solid purple; */}
    font-size: 0.9vw;
    margin: 0.8em 0;
    height: 1em;
    @media screen and (max-width: 1024px){
        font-size: 1.6vw;
    }
    @media screen and (max-width: 768px){
    }

`;

const StyledScrollBarArea = styled(ScrollBarArea)`
    font-size: 0.9vw;
    height: 40vh !important;

    @media screen and (max-width: 1024px){
        font-size: 1.55vw;
    }

    @media screen and (max-width: 768px){
        height: 50vh !important;
    }
`;

const MobileContainer = styled.div`
    display: none;
    @media screen and (max-width: 768px){
        display: flex;
        flex-direction: column;
        width: 95%;
        margin: 0 auto;
        align-items: center;
    }
`;

const StyledNativeNumberInput = styled(NativeNumberInput)`
    width: 100%;
    @media screen and (max-width: 768px){
        width: 60%;
        margin: 0 auto;
    }

`;


class Slide7 extends React.Component {

    state = {
        teamMembers: [],
        selectedMemberIndex: 0,
        isValidated: true,
        nextPressed: false
    };

    static getDerivedStateFromProps(nextProps) {
        return {
            teamMembers: nextProps.teamMembers || []
        }
    }

    isEmpty = (value) => {
        return value === '';
    };

    findFirstFocusableElement = findFirstFocusableElement;

    handleGoForward = () => {
        const teamMembers = this.state.teamMembers;
        const isAnyEmpty = teamMembers.some(member => this.isEmpty(member.months));

        this.setState({
            nextPressed: true
        });

        if (isAnyEmpty) {
            this.setState({
                isValidated: false
            });
            return false;
        } else {
            return true;
        }
    };

    handleMonthsIncrement = (event, memberId) => {
        const newMembers = this.state.teamMembers.map(member => {
            if (member.months >= MAX_MONTH || memberId !== member.id) return member;

            member.months++;
            member.hasChangedManualInput = true;

            return member;
        });
        this.props.doSendSlide7Data(newMembers);
    };

    handleMonthsDecrement = (event, memberId) => {
        const newMembers = this.state.teamMembers.map(member => {
            if (member.months <= MIN_MONTH || member.id !== memberId) return member;

            member.months--;
            member.hasChangedManualInput = true;

            return member;
        });
        this.props.doSendSlide7Data(newMembers);
    };

    valueInRange(value) {
        return value >= MIN_MONTH && value <= MAX_MONTH;
    }

    handleManualChange = (event, memberId) => {
        this.setState({isValidated: true});
        const value = Number(event.target.value);

        // Empty value should be OK
        // value cannot exceed maxlength (somehow React ignores that)
        // value should be between Min and Max values
        if (value !== '' && (+value < MIN_MONTH || (value + '').length > (MAX_MONTH + '').length || (+value > MAX_MONTH))) return;

        const newMembers = this.state.teamMembers.map(member => {
            if (member.id !== memberId) return member;

            member.months = value !== '' ? +value : value;
            member.hasChangedManualInput = true;

            return member;
        });

        this.setState({
            isValidated: newMembers.reduce((previousValue, currentValue) => {
                return previousValue && (currentValue.months !== '' && this.valueInRange(currentValue.months))
            }, true)
        });

        this.props.doSendSlide7Data(newMembers);
    };

    handleNextIndividuals = () => {
        const { selectedMemberIndex } = this.state;
        const amount = this.state.teamMembers.length;
        const validMember = this.state.teamMembers[selectedMemberIndex].months !== '';

        window.scrollTo(0, 0);

        this.setState({
            nextPressed: true
        });

        if (!validMember) {
            return false;
        }

        if (selectedMemberIndex === amount - 1) {
            this.handleGoForward();
            this.props.doGoForward();
            const { selectedMenuItemId } = this.props;
            const stepId = selectedMenuItemId + 1;
            this.props.history.push('/assessment/' + stepId);
            return;
        }
        this.setState(prevState => ({
            selectedMemberIndex: prevState.selectedMemberIndex + 1,
            nextPressed: false // reset
        }));
    };

    handlePrevIndividuals = () => {
        const { selectedMemberIndex } = this.state;
        window.scrollTo(0, 0);
        if (selectedMemberIndex === 0) {
            const { selectedMenuItemId } = this.props;
            this.props.doGoBackward();
            this.props.history.push(`/assessment/${selectedMenuItemId - 1}`);
            return;
        }
        this.setState(prevState => ({ selectedMemberIndex: prevState.selectedMemberIndex - 1 }));
    };

    componentDidMount() {
        this.firstFocusableElement = this.findFirstFocusableElement();

        if (window.gtag) {
            window.gtag('config', Config.GOOGLE_ANALYTICS, {
                'page_title' : 'Slide 7',
                'page_path': `/assessment/7`
            });
        }
    }

    render() {
        const { nextPressed, isValidated } = this.state;
        const { teamMembers } = this.props;
        const selectedMemberIndex = this.state.selectedMemberIndex;
        const { header, description, optional_headline } = this.props.content;
        const slideData = {
            id: 7,
            title: header,
            text: description
        };
        
        return (
            <BaseSlide {...slideData} nextPressed={nextPressed} isValidated={!nextPressed || isValidated} validationErrorMessage="Please fill out all fields." onGoBackward={this.handleGoBackward} onGoForward={this.handleGoForward} hasToBeCenter={false} backLink="6/3" firstFocusableElement={this.firstFocusableElement}>
                <QuestionHeader>
                    <H4 flexBasis="100%">{optional_headline}</H4>
                </QuestionHeader>
                <ExtendedTable>
                    <ExtendedTableHeader style={{ margin: '0', width: '100%'}}>
                        <HeaderColumn>
                            <HeaderCell style={{ width: '60%', flexBasis: '60%', marginLeft: '10%'}}></HeaderCell>
                            <HeaderCell style={{ flexBasis: '30%' }}>Months</HeaderCell>
                        </HeaderColumn>
                        <HeaderColumn>
                            <HeaderCell style={{ width: '60%', flexBasis: '60%', marginLeft: '10%'}}></HeaderCell>
                            <HeaderCell style={{ flexBasis: '30%' }}>Months</HeaderCell>
                        </HeaderColumn>
                    </ExtendedTableHeader>
                    <StyledScrollBarArea>
                        <StyledWrapper>
                        {teamMembers.map(member => (
                            <Row key={member.id}>
                                <RowCell style={{ width: '60%', maxWidth: '60%', flexBasis: '60%', marginLeft: '5%', marginRight: '5%' }} title={member.name}><div className="ellipsis">{member.name}</div></RowCell>
                                <RowCell style={{ flexBasis: '30%' }}>
                                    <StyledNativeNumberInput hasChanged={member.hasChangedManualInput}
                                                             value={member.months || ''}
                                                             placeholder="Enter"
                                                             min={MIN_MONTH}
                                                             max={MAX_MONTH}
                                                             maxlength="3"
                                                             isValidated={!this.state.nextPressed || (member.months !== '' && +member.months >= MIN_MONTH && +member.months <= MAX_MONTH)}
                                                             onDecrement={e => this.handleMonthsDecrement(e, member.id)}
                                                             onIncrement={e => this.handleMonthsIncrement(e, member.id)}
                                                             onChange={e => this.handleManualChange(e, member.id)}/>

                                </RowCell>
                            </Row>
                        ))}
                        </StyledWrapper>

                    </StyledScrollBarArea>
                </ExtendedTable>
                <MobileContainer>
                    <NameWrapper><span>{teamMembers[selectedMemberIndex].name || 'Individual'}</span></NameWrapper>

                    <StyledNativeNumberInput hasChanged={teamMembers[selectedMemberIndex].hasChangedManualInput}
                                             value={teamMembers[selectedMemberIndex].months || ''}
                                             placeholder="Enter"
                                             min={MIN_MONTH}
                                             max={MAX_MONTH}
                                             maxlength="3"
                                             isValidated={!this.state.nextPressed || (teamMembers[selectedMemberIndex].months !== '' && +teamMembers[selectedMemberIndex].months >= MIN_MONTH && +teamMembers[selectedMemberIndex].months <= MAX_MONTH)}
                                             onDecrement={(e) => this.handleMonthsDecrement(e, teamMembers[selectedMemberIndex].id)}
                                             onIncrement={(e) => this.handleMonthsIncrement(e, teamMembers[selectedMemberIndex].id)}
                                             onChange={e => this.handleManualChange(e, teamMembers[selectedMemberIndex].id)}/>
                    <StatementsNavigation
                        isValidated={!nextPressed || teamMembers[selectedMemberIndex].months !== ''}
                        validationErrorMessage="Please enter the number of months"
                        onGoForward={this.handleNextIndividuals}
                        onGoBackward={this.handlePrevIndividuals} />

                </MobileContainer>
            </BaseSlide>
        )
    }
}

const mapStateToProps = (state) => ({
    teamMembers: state.applySlidesData.teamMembers,
    allSidersWereTouched: state.applyAllSlidersWereTouched[6],
    warningMessageShown: state.applySlideWarningShown[6],
    content: {
        ...state.applyContent.steps[6],
        months: state.applyContent.umb_dictionary.months,

    },
    selectedMenuItemId: state.navmenu.selectedMenuItemId

})

const mapDispatchToProps = { doSendSlide7Data, doSetAllSlidersWereTouched, doSetSlideWarningShown, doGoForward, doGoBackward };

export default connect(mapStateToProps, mapDispatchToProps)(Slide7);