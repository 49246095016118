import React from "react";
import styled from "styled-components";
import colors from "../../../Slides/__common__/colors";
const StyledSelect = styled.select`
    color: #FFFFFF;
    font-size: 6.75vw;
    padding: 0.3em 1.5em 0.3em 1.7em;
    background-color: transparent;
    font-weight: 300;
    border: 1px solid #FFFFFF;
    outline: none;
    ${'' /* margin: 0 auto; */}
    text-transform: uppercase;
    letter-spacing: 1pt;

    &:focus{
        border: 1px solid ${colors.turquoise};
        color: ${colors.turquoise};
    }
    option{
        color: black;
    }
`;

const Dropdown = ({ value, onChange, items }) => (
    <StyledSelect value={value} onChange={onChange}>
        {items.map((item, index) => <option key={index} value={index}>{item.name || 'statement ' + (index + 1)}</option>)}
    </StyledSelect>
)

export default Dropdown;

