import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import LogoHeader from "./LogoHeader";
import { findFirstFocusableElement } from '../__common__/firstFocusableHelper';

import Arrow from "../../resources/arrow.svg";
const ExLogoHeader = styled(LogoHeader)`
    margin-bottom: 6vw;
    @media screen and (max-width: 1024px){
        margin-bottom: 10vw;
    }

    @media screen and (max-width: 768px){
        margin-bottom: 20vw;
    }
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100%;
    margin: -10% auto 0 auto;
    
    & .hello-page{
        width: 95%
    }
    & .hello-page p{
        font-size: 4.6vw;
        line-height: 1.5em;
    }

    & .hello-page p > strong{
        font-weight: 500;
    }
    & .hello-page p:nth-child(4){
        margin: 0;
    }
    
    @media (min-width: 768px) {
        margin: 0 auto;
        & .hello-page{
            width: 70%;
        }

        & .hello-page p{
            font-size: 2.1vw;
        }
        & .hello-page p:nth-child(4){
            margin: 0;
        }

    }
    @media (min-width: 1024px) {
        & .hello-page{
            width: 40%;
        }
        & .hello-page p{
            font-size: 1.1vw;
        }
        & .hello-page p:nth-child(4){
            margin: 0;
        }
    }

`;

export const StartLink = styled(Link)`
    border: 1px solid #FFFFFF;
    color: #FFFFFF;
    text-transform: uppercase;
    text-decoration: none;
    font-size: 1.05vw;
    padding: 0.5em 0em;
    width: 10vw;
    margin-top: 2vw;
    transition: 0.15s transform ease-in-out;
    outline-color: 0;
    outline: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    
    &:hover, &:focus{
        transform: scale(1.05); 
    }

    span{
        margin-left: 10%;        
    }

    img{
        width: 12px;
        margin-left: 20%;
    }

    @media screen and (max-width: 1024px){
        font-size: 1.65vw;
        width: 15vw;
    }

    @media screen and (max-width: 768px){
        font-size: 4.5vw;
        width: 40vw;
        margin-top: 5vw;

    }
`;


class Hello extends React.Component {

    state = {
        content: '',
        unsupportedRes: false
    }

    findFirstFocusableElement = findFirstFocusableElement;

    checkScreenResolution = () => {
        const minSupportedRes = 1025;
        if (window.innerWidth < minSupportedRes) {
            this.setState({
               unsupportedRes: true
            });
        } else {
            this.setState({
                unsupportedRes: false
            });
        }
    }

    handleTabPress = (event) =>{
        if (event.key === "Tab" || event.keycode === 9){
            event.preventDefault();
            this.firstFocusableElement.focus();
        }
    }

    componentDidMount() {

        this.checkScreenResolution();

        const { content } = this.props;
        if (content) {
            this.content.innerHTML = content;
        }
        this.firstFocusableElement = this.findFirstFocusableElement();


    }

    render() {
        const { unsupportedRes } = this.state;

        return (
            <Wrapper>
                <ExLogoHeader />
                {unsupportedRes &&
                    <span>Please use desktop version for best experience</span>
                }

                {!unsupportedRes &&
                    <div className="hello-page" ref={ref => this.content = ref}></div>
                }

                {!unsupportedRes &&
                    <StartLink tabIndex="0" onKeyDown={this.handleTabPress} to="/assessment/1"><span>Start</span><img src={Arrow} alt="" /></StartLink>
                }
            </Wrapper>
        )
    }
}

const mapStateToProps = (state) => ({
    content: state.applyContent.welcomeText
})

export default connect(mapStateToProps, {})(Hello);


