import * as React from "react";
import PropTypes from "prop-types";

import { StyledNavLink, Tooltip, ListItem } from "./styles";


export default class NavLink extends React.Component {

    static propTypes = {
        exact: PropTypes.bool.isRequired,
        to: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
        tooltip: PropTypes.string.isRequired,
        isActive: PropTypes.bool,
        onMenuSelect: PropTypes.func.isRequired,
        minPosition: PropTypes.number.isRequired,
        maxPosition: PropTypes.number.isRequired,
        parentPosition: PropTypes.number
    }

    state = {
        hovered: false
    }
    constructor() {
        super();
        this.link = null;
    }

    handleClick = (event) => {
        // Prevents from moving forward
        // @TODO fix that
        if (!this.props.isActive || this.props.id > this.props.selectedMenuItemId) {
            event.preventDefault();
            return false;
        }
        this.props.onMenuSelect(this.props.id);
    }

    handleMouseEnter = () => this.setState({ hovered: true });

    handleMouseLeave = () => this.setState({ hovered: false });

    calculatePosition = () => this.link.offsetTop + this.props.parentPosition;

    createVisibilityStyles = () => {
        if (this.link) {
            const position = this.calculatePosition();
            const visibility = this.hasCorrectPosition(position) ? 'visible' : 'hidden';
            return { visibility };
        }
    }

    hasCorrectPosition = (position) =>
        (position > this.props.minPosition + 20 && position < this.props.maxPosition - 20) ? 1 : 0;

    render() {
        const styles = this.createVisibilityStyles();
        const tooltip = `${this.props.tooltip}`;
        const { to, exact, selectedMenuItemId, id } = this.props;

        /*@TODO fix isActive */

        return (
            <ListItem
                innerRef={x => this.link = x}>
                <Tooltip isVisible={this.state.hovered}>{tooltip}</Tooltip>
                <StyledNavLink
                    onMouseEnter = { this.handleMouseEnter }
                    onMouseLeave = { this.handleMouseLeave }
                    exact={exact}
                    to={to}
                    ok={parseInt(selectedMenuItemId, 10) > id ? "true" : "false"}
                    onClick={this.handleClick}
                    className={parseInt(selectedMenuItemId, 10) === id ? "active" : ''}
                    tabIndex="-1"
                    style={styles}
                />
            </ListItem>
        )
    }
}
