import React from "react";
import styled from "styled-components";

import Logo from "../../Layout/Logo.svg";


const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const H1 = styled.h1`
    margin: 0;
`;


const Img = styled.img`
    width: 20vw;
    @media screen and (max-width: 1024px){
        width: 27.5vw;
        
    }
    @media screen and (max-width: 768px){
        width: 60vw;

    }
`;

const H2 = styled.h2`
    margin: 0;
    font-size: 1.95vw;
    text-transform: uppercase;
    font-weight: 100;
    letter-spacing: 0.5pt;
    transform: translateY(-25%);

    @media screen and (max-width: 1024px){
        font-size: 2.35vw;
        
    }
    @media screen and (max-width: 768px){
        font-size: 4.5vw;

    }

`;

const LogoHeader = ({ className }) => {
    return (
        <Wrapper className={className}>
            <H1>
                <Img src={Logo} alt="Nucleon" />
            </H1>
            <H2>Assessment</H2>
        </Wrapper>
    )
}
export default LogoHeader;

