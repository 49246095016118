import * as React from "react";
import styled from "styled-components";
import QuestionHeader, { H4 } from "../__common__/styled-components";
import BaseSlide from "../BaseSlide";

import { connect } from "react-redux";
import { doSendSlide4Data } from "../../../actions";
import { doSetAllSlidersWereTouched } from '../../../actions/allSlidersWereTouched';
import { doSetSlideWarningShown } from '../../../actions/slideWarningShown';
import { allSlidersTouched, handleSliderTouch, generateSliderState } from "../__common__/RangeInput/sliderParentCallbacks";
import { findFirstFocusableElement } from '../__common__/firstFocusableHelper';
import RangeInput from "../__common__/RangeInput";
import {Config} from "../../../Config";

const ExRangeInput = styled(RangeInput)`
    border-radius: 0;
    
    & > div:first-child{
        border-radius: 0;
    }
    
    & .degrees-container{
        width: 100%;
        // transform: translate(0,5.1vw);
        
        & div {
            text-align: center;
            font-size: 0.75vw;
            position: relative;
            padding: 0 0.1vw;
            width: 100%;
            
            & span{
                position: absolute;
                top: 0;
                left: -10%;
                width: 120%;
            }
            &:first-child{
                transform: translateX(-50%);
            }
            &:last-child{
                transform: translateX(50%);
            }
        }
    }
    @media screen and (max-width: 1024px){
        & .degrees-container{
            // transform: translate(0,6.1vw);
            & div{
                font-size: 1.1vw;
            }
        }  
    }
    @media screen and (max-width: 768px){
        margin-bottom: 12vw;
        margin-top: 25vw;
        
        & .degrees-container{
            // transform: translate(4%,-22.5vw);
            & div{
                font-size: 2.65vw;
        
                & span{
                    position: absolute;
                    top: 0;
                    left: -24%;
                    width: 135%;
                }
            }
        }
    }
`;

class Slide4 extends React.Component {

    state = {
        teamLength: 0,
        bureaucracy: 0,
        showDefaultValuesWarning: false,
        slidersTouched: {
        },
        allSidersWereTouched: false,
        warningMessageShown: false
    }

    handleSliderTouch = handleSliderTouch.bind(this);
    allSlidersTouched = allSlidersTouched.bind(this, 4);
    generateSliderState = generateSliderState.bind(this);
    findFirstFocusableElement = findFirstFocusableElement;

    static getDerivedStateFromProps(nextProps) {
        const { teamLength, bureaucracy } = nextProps;
        return {
            teamLength: teamLength,
            bureaucracy: bureaucracy,
            allSidersWereTouched: nextProps.allSidersWereTouched.allSidersWereTouched,
            warningMessageShown: nextProps.warningMessageShown.warningMessageShown
        }
    }

    handleGoForward = () => {
        if (this.allSlidersTouched() || this.state.allSidersWereTouched || this.state.warningMessageShown) {
            return true;
        } else {
            this.setState({
                showDefaultValuesWarning: true,
                warningMessageShown: true
            });
            this.props.doSetSlideWarningShown(4, { warningMessageShown: true });
            return false;
        }
    }

    handleValueChange = (value) => {
        this.allSlidersTouched();
        this.props.doSendSlide4Data(value);
    }

    componentDidMount() {
        this.firstFocusableElement = this.findFirstFocusableElement();

        if (window.gtag) {
            window.gtag('config', Config.GOOGLE_ANALYTICS, {
                'page_title' : 'Slide 4',
                'page_path': `/assessment/4`
            });
        }
    }

    render() {
        const degrees = this.props.content.teamTime;
        const { bureaucracy } = this.state;
        const { header, description, optional_headline } = this.props.content;
        const slideData = {
            id: 4,
            title: header,
            text: description
        }
        return (
            <BaseSlide {...slideData} onGoForward={this.handleGoForward} hasToBeCenter={true} showDefaultValuesWarning={this.state.showDefaultValuesWarning} defaultValuesWarningMessage="The slider value is set to 0. If the value is correct click NEXT to continue the assessment." firstFocusableElement={this.firstFocusableElement}>
                <QuestionHeader>
                    <H4 flexBasis="45%">{optional_headline}</H4>
                </QuestionHeader>
                <div className="range-input-wrapper">
                    <ExRangeInput type="percent"
                                value={bureaucracy}
                                onValueChange={this.handleValueChange}
                                degrees={degrees}
                                handleSliderTouch={this.handleSliderTouch}
                                name="bureaucracySlider"
                                allSlidersWereTouched={this.state.allSidersWereTouched}
                                slideWarningMessageShown={this.state.warningMessageShown}
                                generateSliderState={this.generateSliderState}/>
                </div>
            </BaseSlide>

        )
    }
}
const mapStateToProps = (state) => {
    return {
        teamLength: state.applySlidesData.teamMembers.length,
        bureaucracy: state.applySlidesData.bureaucracy,
        allSidersWereTouched: state.applyAllSlidersWereTouched[3],
        warningMessageShown: state.applySlideWarningShown[3],
        content: {
            ...state.applyContent.steps[3],
            teamTime: [
                state.applyContent.umb_dictionary.teamtime_lowest,
                state.applyContent.umb_dictionary.teamtime_medium,
                state.applyContent.umb_dictionary.teamtime_highest,
            ]
        }

    }
}

const mapDispatchToProps = { doSendSlide4Data, doSetAllSlidersWereTouched, doSetSlideWarningShown };

export default connect(mapStateToProps, mapDispatchToProps)(Slide4);