import * as React from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import styled from "styled-components";

import QuestionHeader, { H4 } from "../__common__/styled-components";
import NativeNumberInput from "../__common__/NativeNumberInput/NativeNumberInput";
import TeamMember from "../__common__/models/TeamMember"
import { findFirstFocusableElement } from '../__common__/firstFocusableHelper';

import { doSendSlide1Data } from '../../../actions';

import BaseSlide from "../BaseSlide";
import TeamManager from "./TeamManager";
import {Config} from "../../../Config";

const MIN_TEAM_SIZE = 2;
const MAX_TEAM_SIZE = 20;

const StyledNumberInput = styled(NativeNumberInput)`
    margin: 0 1rem;
    width: 2.4vw;
  
    input[type=number] {
        font-size: 1.3vw;
    }
    
    @media screen and (max-width: 1024px){
        & > span{
            min-width: 3.5vw;
        }
    
        input[type=number] {
            font-size: 1.5vw;
        }
    }

    @media screen and (max-width: 768px){
        input[type=number] {
            font-size: 7vw;
            width: 15vw;
        }
    }
`;

class Slide1 extends React.Component {
    constructor(props) {
        super(props);

        const { teamMembers } = this.props;

        this.state = {
            teamMembers: teamMembers,
            teamSize: teamMembers.length,
            currentChar: 68,
            isValidated: true,
            nextPressed: false
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            ...prevState,
            teamMembers: nextProps.teamMembers
        }
    }

    handleIncrement = (incrementBy = 1) => {
        const membersAmount = this.state.teamMembers.length;
        if (membersAmount === MAX_TEAM_SIZE) {
            this.setState({
                teamSize: membersAmount
            });
            return;
        }
        // @TODO warn why cannot be more than 20

        const { teamMembers, currentChar } = this.state;
        this.setState({
            currentChar: currentChar + incrementBy
        });

        const members = [...teamMembers];

        for (let i = 0; i < incrementBy; i++) {
            members.push(new TeamMember('', 'Type name here'));
        }

        this.setState({
            teamSize: members.length
        }, () => {
            this.props.doSendSlide1Data(members);
        });
    };

    handleIncrementByOne = this.handleIncrement.bind(this, 1);
    findFirstFocusableElement = findFirstFocusableElement;

    handleDecrement = (decrementBy = 1) => {
        const membersAmount = this.state.teamMembers.length;
        if (membersAmount === MIN_TEAM_SIZE) {
            this.setState({
                teamSize: membersAmount
            });
            return;
        }
        // @TODO warn why not handling decrement

        const { teamMembers, currentChar } = this.state;
        this.setState({
            currentChar: currentChar - decrementBy
        });

        let members = teamMembers.slice();

        for (let i = 0; i < decrementBy; i++) {
            let emptyMemberIndexes = members.map((member, index) => {
                return {
                    isEmpty: member.name === '',
                    index
                }
            }).filter(member => member.isEmpty),
                indexToSplice;

            if (emptyMemberIndexes.length === 0) {
                // If no empty member can be found
                // Remove last team member
                indexToSplice = members.length - 1;
            } else {
                // Remove team member without name first
                indexToSplice = emptyMemberIndexes.pop().index;
            }

            members.splice(indexToSplice, 1);
        }

        this.setState({
            teamSize: members.length
        }, () => {
            this.props.doSendSlide1Data(members);
        });
    };

    handleDecrementByOne = this.handleDecrement.bind(this, 1);

    handleMemberChange = (event, id) => {
        const teamMembers = this.state.teamMembers;
        const value = event.target.value;
        const members = teamMembers.reduce((arr, curr) => {
            if (curr.id === id) {
                curr.untrimmed = value;
                curr.name = value.trim();
            }
            return [...arr, curr];
        }, []);

        const isAnyEmpty = members.some(item => this.isEmpty(item.name));

        this.setState({
            isValidated: !isAnyEmpty
        });

        this.props.doSendSlide1Data(members);
    };

    isEmpty = (value) => !!!value;

    // Check if any input fields are empty. isAnyEmpty returns true if any are missing.
    handleGoForward = () => {
        const teamMembers = this.state.teamMembers;
        const isAnyEmpty = teamMembers.some(item => this.isEmpty(item.name));

        this.setState({
            nextPressed: true,
            isValidated: !isAnyEmpty
        });

        return !isAnyEmpty;
    };

    handleManualChange = (event) => {
        const value = Number(event.target.value),
            currentTeamSize = this.state.teamMembers.length,
            self = this;

        // don't accept input with length more than 2
        if (value.length > 2) {
            return;
        }

        if (this.manualChangeTimeout) {
            clearTimeout(this.manualChangeTimeout);
        }

        this.setState({
            teamSize: !!value ? +value : value // desired teamSize (will be update after setTimeout is running)
        }, () => {
            // Set timeout is set to prevent from updating value on each key enter (like trying to enter 15 will result in first reducing the number to ...2 (because its minimal
            // and then to 25 (because in meantime we have entered 5 - so 25 not 15 gets interpreted and this results in 20 because we have max 20 members)
            this.manualChangeTimeout = setTimeout(() => {
                let newTeamSize = self.state.teamSize || currentTeamSize;
                let diff = (newTeamSize ? Math.max(Math.min(+newTeamSize, MAX_TEAM_SIZE), MIN_TEAM_SIZE) : currentTeamSize) - currentTeamSize; // Check if there is any difference in size

                if (diff > 0) {
                    self.handleIncrement(diff);
                    this.manualChangeTimeout = null;
                    return;
                } else if (diff < 0) {
                    self.handleDecrement(-diff);
                    this.manualChangeTimeout = null;
                    return;
                }

                // reset team size if diff is 0 but use may input different value (like max reached and user inputs 500)
                this.setState({
                    teamSize: currentTeamSize
                });

            }, 700);
        });

    };

    componentDidMount() {
        this.firstFocusableElement = this.findFirstFocusableElement();

        if (window.gtag) {
            window.gtag('config', Config.GOOGLE_ANALYTICS, {
                'page_title' : 'Slide 1',
                'page_path': `/assessment/1`
            });
        }
    }

    render() {
        const { teamMembers, teamSize, isValidated, nextPressed } = this.state;
        const { header, description, optional_headline, peopleInTheTeam } = this.props.content;
        const slideData = {
            id: 0,
            title: header,
            text: description
        };

        return (
            <BaseSlide nextPressed={nextPressed} {...slideData} isValidated={!nextPressed || isValidated} validationErrorMessage="Please fill out all fields." onGoForward={this.handleGoForward} hasToBeCenter={true} hasLimitedHeight={true} firstFocusableElement={this.firstFocusableElement} >
                <QuestionHeader>
                    <H4 flexBasis="45%">{optional_headline}</H4>
                    <H4 flexBasis="50%" right style={{ textAlign: 'center' }}>{peopleInTheTeam}</H4>
                    <StyledNumberInput
                        value={teamSize + ''}
                        isValidated={true}
                        onIncrement={this.handleIncrementByOne}
                        onDecrement={this.handleDecrementByOne}
                        min={MIN_TEAM_SIZE}
                        max={MAX_TEAM_SIZE}
                        onChange={this.handleManualChange}
                    />
                </QuestionHeader>
                <TeamManager teamMembers={teamMembers} onMemberChange={this.handleMemberChange} />
            </BaseSlide>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        navmenuState: state.navmenu,
        teamMembers: state.applySlidesData.teamMembers,
        content: { ...state.applyContent.steps[0], peopleInTheTeam: state.applyContent.umb_dictionary.people_in_the_team },
    }
};

const mapDispatchToProps = { doSendSlide1Data };
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Slide1));